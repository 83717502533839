import React from 'react';
import { render } from "react-dom";
import App from './App';
import { Router } from 'react-router-dom';
import UserProvider from './providers/UserProvider';
import PackageProvider from './providers/PackageProvider';
import NotificationProvider from './providers/NotificationProvider';
import DrawerProvider from './providers/DrawerProvider';
import TenantProvider from './providers/TenantProvider';
import ErrorBoundary from './components/errorBoundary';
import history from "./history.js";
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query'
import TagManager from 'react-gtm-module'
import BrowserSupport from './components/BrowserSupport';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
})

TagManager.initialize({
  gtmId: 'GTM-WM2JDPM',
})

render(
  <ErrorBoundary>
    <BrowserSupport
      supportedBrowsers={{
        Chrome: 63, //chrome version > 63
        Edge: 79,
        Firefox: 37,
        IE: undefined, //not supported
        Opera: 49,
        Safari: 14,
        Facebook: 372
        // other browsers supported
      }}
    >
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <PackageProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <TenantProvider>
                    <SnackbarProvider maxSnack={5}>
                      <App />
                    </SnackbarProvider>
                  </TenantProvider>
                </DrawerProvider>
              </NotificationProvider>
            </PackageProvider>
          </UserProvider>
        </QueryClientProvider>
      </Router>
    </BrowserSupport>
  </ErrorBoundary>,
  document.getElementById("root")
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
