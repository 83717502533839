import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { Redirect, Route } from "react-router-dom"
import Profile from "../../layouts/profile"
import { UserContext } from "../../providers/UserProvider"
import strings from "../trans"
function UnauthorizedPage(props) {
    return <Profile {...props} noExpansion>
        <Grid justifyContent="center">
            <Grid item xs={12}>
                <Typography align="center" variant="h1" style={{ color: 'gray' }}>
                    401
                </Typography>
                <Typography align="center" gutterBottom variant="h4" >
                    {strings.msg_UNAUTHORIZED}
                </Typography>
                <Typography align="center" variant="body2" >
                    {strings.msg_authorized_message}
                </Typography>
            </Grid>
        </Grid>
    </Profile>
}
export default function ProtectedRoute(props) {
    const { roles } = props
    const userContext = React.useContext(UserContext)
    return userContext.user
        ? roles
            ? userContext.hasOneOfRoles(roles)
                ? <Route {...props} />
                : <Route render={(props) => <UnauthorizedPage {...props} />} />
            : <Route {...props} />
        : props.location.pathname === "/"
            ? <Redirect to={{ pathname: '/home', }} />
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
            }} />
}