import React from "react"
import { Redirect, Route, useHistory } from "react-router-dom"
import { UserContext } from "../../providers/UserProvider"
export default function AuthRoute(props) {
    const history = useHistory()
    const userContext = React.useContext(UserContext)
    let redirect = props.location.state?.from?.pathname || '/'
    if (userContext.user)
        history.replace(redirect)
    return userContext.user
        ? <Redirect to={redirect} />
        : <Route {...props} />
}