import './BrowserSupport.css'
import browserImages from './browserImages.json'
export default function BrowserSupport() {

    return (
        <>
            <div className="text-center container">
                <div className="heading">
                    Unsupported Browser
                    <br />
                    المتصفح غير مدعوم
                </div>
                <div className="description-1">
                    It looks like you are viewing this application on unsupported browser
                    <br />
                    يبدو أنك تحاول الوصول الى هذا التطبيق عن طريق متصفح غير مدعوم
                </div>
                <div className="description-2">
                    Please update your browser to latest version
                    <br />
                    رجاء قم بتحديث المتصفح الخاص بك الى اخر إصدار
                </div>
                <div className="browser-names">
                    {
                        browserImages.map(browserImage => (
                            <div
                                className='supported-browser'
                                key={browserImage.title}
                            >
                                <img
                                    src={browserImage.image}
                                    alt={browserImage.title}
                                />
                                <div>
                                    <a
                                        target="_blank"
                                        href={browserImage.downloadURL}
                                    >
                                        {browserImage.title}
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}