import React from 'react';
import { Button } from "@material-ui/core";
import { crashReportAPI } from '../../api';
import { browserVersion, browserName } from 'react-device-detect';

if (!('toJSON' in Error.prototype))
    Object.defineProperty(Error.prototype, 'toJSON', {
        value: function () {
            var alt = {};

            Object.getOwnPropertyNames(this).forEach(function (key) {
                alt[key] = this[key];
            }, this);

            return alt;
        },
        configurable: true,
        writable: true
    });

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isReporting: true,
            errorId: null,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        if (JSON.stringify(error).includes("Loading chunk"))
            window.location.reload()
        else
            return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        if (JSON.stringify(error).includes("Loading chunk"))
            window.location.reload()
        else {
            this.setState({ hasError: true, })
            crashReportAPI.post('', { browserVersion, browserName, message: error.message, error: JSON.stringify(error), errorInfo: errorInfo.componentStack })
                .then(res => this.setState({ errorId: res.data }))
                .catch(err => { })
                .finally(() => this.setState({ isReporting: false }))
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div>
                <h1>Something went wrong.</h1>
                {
                    this.state.isReporting ?
                        'logging to server...'
                        :
                        this.state.errorId ? `contact with support using error id ${this.state.errorId}` : 'done'
                }
                <Button
                    onClick={
                        () => {
                            this.setState({
                                hasError: false,
                                isReporting: true,
                                errorId: null,
                            })
                            window.location.reload();
                        }}
                >refresh</Button>
            </div>;
        }

        return this.props.children;
    }
}
export default ErrorBoundary