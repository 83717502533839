import React from "react";
import strings, { language } from "../trans";
import { Link as RouterLink } from "react-router-dom";
import safelogo from "../../assets/images/SAFE-Logo.png";
import clsx from "clsx";

const DashboardFooter = ({ className }) => {
    return (
        <footer className={clsx("footer py-3", className)} style={{ flexGrow: 0 }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-between">
                        <p className="footer-content" style={{ marginBottom: 0 }}><RouterLink style={{ color: "white" }} to="/terms">{strings.msg_terms} </RouterLink></p>
                        <p className="footer-content" style={{ marginBottom: 0 }}>{strings.msg_footer_1}<a href="https://safe-sas.com/" target="_blank" style={{ color: "white" }}> <img src={safelogo} width="27px" alt="SAFE Logo" /> </a></p>
                        <p className="footer-content" style={{ marginBottom: 0 }}>
                            {strings.msg_footer_3}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
};
export default DashboardFooter;