import React, { Component, createContext } from "react";
import { packageAPI } from "../api";
export const PackageContext = createContext({ packages: [], freePackages: [], isLoading: true });
class PackageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            freePackages: [],
            isLoading: true,
        };
    }
    componentDidMount = () => {
        packageAPI
            .get()
            .then((response) => {
                const packages = response.data.sort((a, b) => a.count - b.count);
                this.setState({
                    packages: packages.filter(p => p.price),
                    freePackages: packages.filter(p => !p.price),
                    isLoading: false
                })
            })
            .catch((err) => {
                this.setState({
                    isLoading: false,
                })
            })
    };
    render() {
        return (
            <PackageContext.Provider value={this.state}>
                {this.props.children}
            </PackageContext.Provider>
        );
    }
}
export default PackageProvider;