import React, { Component, createContext } from "react";
import { tenantAPI } from "../api";
import { firebase } from "../components/firebase";
export const TenantContext = createContext({ user: {} });
const FEATURES = {
    SCHEDULED_DOCUMENTS: 'schedule-documents',
}
class TenantProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {}
        };
    }
    getTenantInfo = () => {
        tenantAPI
            .get('my')
            .then((response) => {
                this.setState({ user: response.data })
            })
            .catch((err) => console.log(err));
    }
    componentDidMount = () => {
        firebase.auth().onAuthStateChanged(userAuth => {
            if (userAuth)
                this.getTenantInfo()
        });
    };
    hasFeature = (name) => {
        return this.state?.user?.features?.includes(name)
    }
    render() {
        return (
            <TenantContext.Provider value={{ ...this.state, hasFeature: this.hasFeature }}>
                {this.props.children}
            </TenantContext.Provider>
        );
    }
}
export default TenantProvider;
export { FEATURES };