import React, { Component, createContext } from "react";
import { firebase } from "../components/firebase";
export const UserContext = createContext({ user: null, isLoading: true, authLoading: false, setLoading: (val) => { } });
const auth = firebase.auth()
const USER_ROLES = {
    SUPER_ADMIN: 'super-admin',
    ADMIN: 'admin',
    SUBMITTER: 'submitter',
    VIEWER: 'viewer',
}
class UserProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            isLoading: true,
            authLoading: false,
            setLoading: this.setLoading,
            setUser: this.setUser,
            claims: null,
            hasOneOfRoles: this.hasOneOfRoles
        };
    }

    setLoading = (authLoading) => {
        this.setState({ authLoading })
    }

    hasOneOfRoles = (roles=[]) => !!roles.find(role => this.state.claims && this.state.claims[role] === true)
    
    componentDidMount = () => {
        auth.onAuthStateChanged(userAuth => {
            this.setState({ user: userAuth });
            if (userAuth)
                userAuth.getIdTokenResult(true)  // true: force refresh to check if claims changed 
                    .then(res => this.setState({ claims: res.claims }))
                    .catch(err => { })
                    .finally(() => this.setState({ isLoading: false }))
            else this.setState({ isLoading: false })
        });
    };
    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider;
export { USER_ROLES };