import React, { Component, createContext } from "react";
const drawerState = localStorage.getItem('drawerState') === null ? true : localStorage.getItem('drawerState') === 'true'
export const DrawerContext = createContext({ open: drawerState, toggleDrawer: () => { } });
class DrawerProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: drawerState,
            toggleDrawer: this.toggleDrawer
        };
    }
    toggleDrawer = () => {
        localStorage.setItem('drawerState', !this.state.open ? 'true' : 'false')
        this.setState({ open: !this.state.open })
    }
    render() {
        return (
            <DrawerContext.Provider value={this.state}>
                {this.props.children}
            </DrawerContext.Provider>
        );
    }
}
export default DrawerProvider;