export default {
    en: {
        msg_tax_amount_per_item: "tax amount per item (amount * quantity)",
        msg_submit_and_delete: "Submit & Delete",
        msg_item_with_internal_code: "item with internal code",
        msg_is_already_exist: "is already exist",
        msg_keep_field_empty_password: "keep field empty, if you don't need to change password",
        msg_UNAUTHORIZED: "UNAUTHORIZED",
        msg_authorized_message: "Please contact support if you think there is something wrong",
        msg_user_form: 'User form',
        msg_create_user: 'Create User',
        msg_role: 'Role',
        msg_users: 'Users',
        msg_draft_info: 'Draft Info',
        msg_print: 'Print',
        msg_draft_name: 'Draft Name',
        msg_draft_form: 'Drafts Form',
        msg_drafts: 'Drafts',
        msg_daily_status: 'Daily Status',
        msg_schedule_documents: 'Schedule Documents',
        msg_schedule_documents_description: 'Maximize efficiency by planning and scheduling your document uploads in advance',
        msg_title: "Title",
        msg_schedule_upload_form: "Scheduled Upload Form",
        msg_schedule_documents_due_date_month_not_match: "Ensure that all documents share the same due date month",
        msg_scheduled_upload: 'Scheduled Upload',
        msg_update_status_date: 'Status Update Date',
        msg_sent_date: 'Sent Date',
        msg_documents_per_day: 'Documents per day',
        msg_due_date: 'Due date',
        msg_are_u_sure_u_want_to_cancel_this_submission: 'Are you sure you want to cancel this submission?',
        msg_are_u_sure_u_want_to_start_this_service: 'Are you sure you want to start this service manually?',
        msg_proceed: 'Proceed',
        msg_canceled_successfully: 'Canceled successfully',
        msg_run_scheduler_manually: 'Start submission service manually',
        msg_service_started_successfully: "Service started successfully",
        msg_save_as_draft: 'Save as draft',
        msg_function_longer_supported_by_ETA: "This function has been disabled as it's no longer supported by ETA API",
        msg_invoice_items: 'invoice items',
        msg_invoice_items_is_empty: 'invoice items is empty',
        msg_first_name: 'First Name',
        msg_last_name: 'Last Name',
        msg_receiver_id_required: 'Required if total more than 50,000',
        msg_job_title: 'Job Title',
        msg_thanks_for_registering: 'Thanks for Registering',
        msg_registered_free_package_instruction: 'You will receive an email confirming that your account is activated.',
        msg_registered_paid_package_instruction: 'You will receive an invoice email with bank details that you will use to pay for the selected package.',
        msg_delete_confirmation: 'Are you sure you want to delete this item permanently?',
        msg_edit: 'Edit',
        msg_click_to_know_invalidity: 'click to know the reason of invalidity',
        msg_yes: "YES",
        msg_no: "no",
        msg_cancel_subscription_request: "Are you sure you want to cancel subscription request",
        msg_invoice_or_note_limit: 'Invoices/Notes limit',
        msg_duration: 'Duration',
        msg_price: "Price",
        msg_purchase_order: "Purchase Order (PO)",
        msg_subscriptions: "Subscriptions",
        msg_validating_invoices: 'Validating invoices',
        msg_deleted_successfully: 'Deleted successfully',
        msg_submitted_successfully: 'Submitted successfully',
        msg_total_invoices: 'Total Invoices',
        msg_valid_invoices: 'Valid Invoices',
        msg_invalid_invoices: 'Invalid Invoices',
        msg_documents_and_notes: 'invoices/notes',
        msg_show_items: 'Show items',
        msg_show_errors: 'Show Errors',
        msg_invoices_and_notes_items: 'invoices/notes items',
        msg_invoices_and_notes: 'invoices/notes',
        msg_errors: 'Errors',
        msg_failed_to_load_issuer_details: 'failed to load issuer details',
        msg_failed_to_load_receivers_details: 'failed to load receivers details',
        msg_failed_to_load_products_details: 'failed to load products details',
        msg_upload_file_first: 'Upload file first. Excel should contain two sheets invoices and items',
        msg_convert_excel_file: '1/5 convert Excel file',
        msg_loading_issuer_and_receivers_details: '2/5 loading issuer and receivers details',
        msg_loading_products_details: '3/5 loading products details',
        msg_loading_default_values: '4/5 loading default values',
        msg_computing: '5/5 computing',
        msg_invoice_sheet_not_found: "Couldn't find (invoices, items) sheets in uploaded Excel file",
        msg_maximum_number_of_invoices: 'Maximum allowed document count per submission 50 invoices inside the Excel file to ensure its acceptance',
        msg_excel_contains_2_sheets: "The Excel file must contain 2 sheets, (invoices) for invoices' details (issuer, receiver...) and (items) for invoices' line items (products sold).",
        msg_specify_receiver_products_internal_id: "Just specify (Receiver, Good/Service)'s internal-id if you added through our dashboard in profile section. Else you need to specify all of fields.",
        msg_first_option_full_invoices: "No calculations needed and excel file contains all fields required by Egyptian Tax Authority.",
        msg_second_option_simplified_invoices: "Only upload mandatory fields and let us calculate missed fields (net total, net amount, ...).",
        msg_download_invoices_template: 'DOWNLOAD INVOICES TEMPLATE',
        msg_upload_invoices: 'UPLOAD INVOICES',
        msg_options: 'Options',
        msg_convert: 'Convert',
        msg_upload_documents: 'Upload Invoices (Excel)',
        msg_duplicating_line_item: "Item code already exists, please edit the line item instead of adding a new one",
        msg_eta_profile_category: 'TAX AUTHORITY',
        msg_rabet_profile_category: 'RABET',
        msg_faq_section: 'FAQ',
        msg_profile_settings_env_helper_text: 'Contact support if you need to change your environment.',
        msg_profile_settings: 'Personal info',
        msg_export_as_csv: 'Export invoices as CSV',
        msg_production_helper_text: 'environment has to be chosen for most cases.',
        msg_preproduction_helper_text_1: 'Only check the',
        msg_preproduction_helper_text_2: 'option if you feel you need to test (invoices submission, Excel submission, USB token, ... etc). however, pre-production needs more configuration steps and certificate installation.',
        msg_tenant_name_helper_text: 'Will be used during login, it is similar to the organization name.',
        msg_company_name: 'Company Name',
        msg_phone_number: 'Phone Number',
        msg_show_documents: 'Show documents',
        msg_submissions_rabet: 'Submissions (Rabet)',
        msg_submissions: 'Submissions',
        msg_waiting_for_signing: 'Waiting for signing',
        msg_processing: 'Processing',
        msg_production: 'production',
        msg_preproduction: 'pre-production',
        msg_server_env: 'Environment',
        msg_company_registration_number_help_text: 'Taxpayer tax registration number (according to your tax card), Make sure the number is correct to send your invoices successfully',
        msg_notification_preferences: 'Notification Preferences (manage)',
        msg_notification_chanel: 'Change notification channel to ERP',
        msg_primary_erp: 'Primary ERP',
        msg_ensure_primary_erp: 'Ensure primary ERP option is checked',
        msg_package_request_sent_successfully: 'Package Request Sent successfully',
        msg_download_failed: 'Download failed',
        msg_new_copy_of_invoice: 'New copy of invoice',
        msg_copied_to_clipboard: 'copied to clipboard',
        msg_my_codes: 'My Codes',
        msg_signed: 'Signed',
        msg_not_signed: 'Not Signed',
        msg_dashboard_submission_id: 'Submission id (Rabet)',
        msg_dashboard_submission_uuid: 'Submission uuid (Rabet)',
        msg_submission_uuid_eta: 'Submission uuid (ETA)',
        msg_edit_document: 'Edit Document',
        msg_documents_count: 'Documents Count',
        msg_item_name: 'Item Name',
        msg_receiver_internal_code: 'Receiver Internal Code',
        msg_profile_title_meta: 'Profile | Rabet E-Invoice linking services',
        msg_dashboard_title_meta: 'Dashboard | Rabet E-Invoice linking services',
        msg_home_title_meta: 'Home | Rabet E-Invoice linking services',
        msg_subscribe: 'Subscribe',
        msg_days: 'Days',
        msg_remaining_documents: 'Remaining Invoices/Notes',
        msg_remaining_days: 'Remaining Days',
        msg_decline_cancellation: 'Decline Cancellation',
        msg_decline_rejection: 'Decline Rejection',
        msg_reject: 'Reject',
        msg_all: 'All',
        msg_credit_note: 'Credit Note',
        msg_debit_note: 'Debit Note',
        msg_all_documents: 'All',
        msg_documents_received: 'Received',
        msg_documents_sent: 'Sent',
        msg_code_active_yes: 'active',
        msg_code_active_no: 'inactive',
        msg_notification_type_delegation_invite: 'delegation invite',
        msg_notification_type_otp: 'OTP',
        msg_notification_profile_data_validation: 'profile data validation',
        msg_notification_type_generic_notification: 'generic notification',
        msg_notification_type_receive_download_ready: 'receive download ready',
        msg_notification_type_document_received: 'received',
        msg_notification_type_document_validated: 'validated',
        msg_notification_type_document_cancelled: 'cancelled',
        msg_notification_type_document_rejected: 'rejected',
        msg_package_expired_yes: 'yes',
        msg_package_expired_no: 'no',
        msg_package_status_in_progress: 'in progress',
        msg_package_status_complete: 'complete',
        msg_package_status_error: 'error',
        msg_package_status_deleted: 'deleted',
        msg_package_type_full: 'full',
        msg_package_type_summary: 'summary',
        msg_package_format_csv: 'CSV',
        msg_package_format_xml: 'XML',
        msg_package_format_json: 'JSON',
        msg_mixed: 'Mixed',
        msg_structure_invalid: 'Invalid Structure',
        msg_pending: 'Pending',
        msg_you_should_specify_rate_or_amount: 'you should specify rate or amount',
        msg_credit_note_submission_total_error: 'Total amount of the credit note should be less than or equal the total amount of referenced invoices: ',
        msg_debit_note_submission_total_error: 'Total amount of the debit note should be more than or equal the total amount of referenced invoices: ',
        msg_or_create_new_item: 'or Create new product',
        msg_email_or_password_is_wrong: 'Email or password is wrong',
        msg_eta_dashboard: 'ETA Dashboard',
        msg_navigate_to_eta_profile: 'Navigate to ETA profile page',
        msg_ERP: 'ERP',
        msg_register_erp_or_edit_one: 'Register an ERP or Edit an existing one',
        msg_callback_url: 'Callback URL',
        msg_copy_and_paste_in_callback_url_field: 'copy and paste in callback URL field',
        msg_copy_and_paste_in_api_key_field: 'copy and paste in API key field',
        msg_api_key: 'API key',
        msg_notifications_settings: 'Notification Settings',
        msg_view_invoice: 'View Invoice',
        msg_view_response: 'View ETA Response',
        msg_accepted: 'Accepted',
        msg_failed: 'Failed',
        msg_eta_submission_response: 'ETA Submission Validation',
        msg_eta_document_response: 'ETA Validation',
        msg_created_at: 'Creation Date',
        msg_updated_at: 'Update Date',
        msg_dashboard_document_id: 'Document uuid (Rabet)',
        msg_eta_response: 'Tax Authority validation',
        msg_submitted_documents: 'Submitted Documents',
        msg_default_issuer: 'Default Issuer',
        msg_delete: 'Delete',
        msg_invoice_or_note: 'invoice / note',
        msg_signing_agent_file_app: 'Signing Agent App',
        msg_config_file: 'Config File',
        msg_service_account_file: 'Service Account File',
        msg_service_account: 'Service Account',
        msg_onboarding: 'Signature Configuration',
        msg_or_create_new_issuer: 'or Create new issuer (branch)',
        msg_or_create_new_receiver: 'or Create new receiver',
        msg_available_issuers: 'Available issuers (branches)',
        msg_available_receivers: 'Available receivers',
        msg_package_subscription_status: 'Package Subscription Status',
        msg_documents_charged: 'Documents charged',
        msg_days_passed: 'Days passed',
        msg_verify: 'Verify',
        msg_confirm: 'Confirm',
        msg_change_password: 'Change Password',
        msg_password: 'Password',
        msg_forget_your_password: 'Forget your password?',
        msg_password_reset: 'Password Reset',
        msg_logo_title: 'E-Invoicing Dashboard',
        msg_english: 'English',
        msg_arabic: 'عربي',
        msg_profile: 'Profile',
        msg_logout: 'Logout',
        msg_no_recent_notification: 'No Recent Notification',
        msg_see_more: 'See More',
        msg_create_document: 'Create Invoice (Manual)',
        msg_documents: 'Invoices/Notes',
        msg_document_reports: 'Documents and Notes',
        msg_document_reports_etax: 'Documents and Notes (ETA)',
        msg_document_reports_rabet: 'Documents and Notes (Rabet)',
        msg_packages: 'Combined Invoices',
        msg_types: 'Types',
        msg_notifications: 'Notifications',
        msg_pubsub: 'Withheld Documents',
        msg_codes: 'Codes',
        msg_item_codes: 'Item Codes (Enquiry)',
        msg_invoice: 'Invoice',
        msg_export_invoice: 'Export Invoice',
        msg_invoice_credit: 'Credit note',
        msg_invoice_debit: 'Debit note',
        msg_export_invoice_credit: 'Export credit note',
        msg_export_invoice_debit: 'Export debit note',
        msg_submission: 'Submission',
        msg_issuance_date: 'Issuance Date',
        msg_submission_date: 'Submission Date',
        msg_issuer: 'Issuer',
        msg_issuer_from: 'Issuer (From)',
        msg_receiver: 'Receiver',
        msg_receiver_to: 'Receiver (To)',
        msg_line_items: 'Line Items',
        msg_order: 'Order',
        msg_order_details: 'Order Details',
        msg_payment: 'Payment',
        msg_delivery_information: 'Delivery Information',
        msg_signatures: 'Signatures',
        msg_validations: 'Validations',
        msg_download: 'Download',
        msg_name: 'Name',
        msg_registration_number: 'Tax Registration Number',
        msg_type: 'Type',
        msg_sub_type: 'Sub Type',
        msg_address: 'Address',
        msg_addresses: 'Branches Address',
        msg_tax_activity_code: 'Tax Activity Code',
        //material-table-localization
        msg_of: "of",
        msg_first_page: "First Page",
        msg_previous: "Previous",
        msg_next: "Next",
        msg_last_page: "Last Page",
        msg_rows: "Rows",
        msg_search: "Search",
        msg_no_records: "No Records",
        msg_actions: "Actions",
        msg_refresh: "Refresh",
        msg_internal_code: "Internal Code Good/Service",
        msg_internal_items: "Internal Goods & Services",
        msg_internal_id: "Internal ID",
        msg_purchase_order_description: "Purchase Order Description",
        msg_purchase_order_reference: "Purchase Order Reference",
        msg_proforma_invoice_number: "Proforma Invoice Number",
        msg_sales_order_description: "Sales Order Description",
        msg_sales_order_reference: "Sales Order Reference",
        msg_bank_account_iban: "Bank Account IBAN",
        msg_swift_code: "Swift Code",
        msg_bank_account_no: "Bank Account No",
        msg_bank_name: "Bank Name",
        msg_payment_terms: "Payment Terms",
        msg_bank_address: "Bank Address",

        msg_packaging: "Packaging",
        msg_delivery_method: "Delivery Method",
        msg_net_weight_kg: "Net Weight KG",
        msg_gross_weight_kg: "Gross Weight KG",
        msg_country_of_origin: "Country Of Origin",
        msg_export_port: "Export Port",
        msg_validity_date: "Validity Date",
        msg_delivery_terms: "Delivery Terms",
        msg_signed_by: "Signed By",

        msg_internal_id_helper_text:
            "This ID can be used to easily reach which document you are referring to in your own organization",
        msg_local_time_zone: "Local Time Zone",
        msg_date: "Date",
        msg_date_time_issued: "Date Time Issued",
        msg_service_delivery_date: "Service Delivery Date",
        msg_document_type: "Document Type",
        msg_document_type_version: "Document Type Version",
        msg_basic_information: "Basic Information",
        msg_confirmation: "Confirmation",
        msg_issuer_and_receiver: "Issuer & Receiver",
        msg_issuer_address: "Issuer Address",
        msg_issuer_addresses: "Branches",
        msg_receiver_address: "Branches Address",
        msg_receiver_addresses: "Receiver Information",
        msg_branch_id: "Branch ID",
        msg_id: "id",
        msg_back: "Back",
        msg_submit: "Submit",
        msg_add: "Add",
        msg_bulk_add: "Bulk Add",
        msg_save: "Save",
        msg_remove: "Remove",
        msg_business: "Business",
        msg_person: "Person",
        msg_foreigner: "Foreigner",

        msg_country: "Country",
        msg_governate: "Governate",
        msg_city: "City",
        msg_building_number_or_name: "Building No. / name",
        msg_room_number: "Room No.",
        msg_floor_number: "Floor No.",
        msg_street: "Street",
        msg_landmark: "Landmark",
        msg_additional_information: "Additional Information",

        msg_version: "Version",
        msg_issuer_id: "Issuer Id",
        msg_issuer_name: "issuer name",
        msg_receiver_id: "Receiver Tax registration",
        msg_receiver_name: "Receiver name",
        msg_submission_id: "Submission uuid",
        msg_uuid: "Document ID",
        msg_eta_document_uuid: "Document uuid (ETA)",
        msg_status: "Status",
        msg_invoice_status: "Document Status",
        msg_net_amount: "Net Amount",
        msg_date_time_received: "Date Time Received",
        msg_date_time_delivered: "Date Time Delivered",
        msg_date_time_created: "Created",
        msg_cancel_or_reject: "Cancel/Reject",
        msg_credit_or_debit: "Credit/Debit",

        msg_document_count: "Document Count",
        msg_submission_details: "Submission Details",
        msg_package_id: "Report Id",
        msg_format: "format",
        msg_expired: "Expired",
        msg_deletion_date: "Deletion Date",
        msg_requestor_taxpayer_RIN: "Taxpayer RIN",
        msg_requestor_name: "Requestor Name",
        msg_requestor_rin: "Requestor RIN",
        msg_request_package_file: "Request Combined Invoices File",
        msg_package_requests: "Combined Invoices Requests (Query is from the tax authority server)",
        msg_valid: "Valid",
        msg_invalid: "Invalid",
        msg_canceled: "Canceled",
        msg_rejected: "Rejected",
        msg_submitted: "Submitted",
        msg_cancellation_request: 'Cancellation Request',
        msg_rejection_request: 'Rejection Request',
        msg_document_types: "Document Types",
        msg_from: "From",
        msg_to: "To",
        msg_receiver_type: "Receiver Type",
        msg_id_code: "Id",
        msg_description: "Description",
        msg_active_from: "Active From",
        msg_active_to: "Active To",
        msg_view: "view",
        msg_value: "value",
        msg_document_workflow_parameters: "Document Workflow Parameters",
        msg_code: "Code",
        msg_taxes: "Taxes",
        msg_sub_taxes: "Sub Taxes",
        msg_non_taxable: "Non Taxable",
        msg_units: "Units",
        msg_weight_units: "Weight Units",
        msg_tax_code: "Tax Codes",
        msg_unit_type: "Unit Type",
        msg_measuring_unit: "Measuring Unit",
        msg_weight_quantity: "Weight unit type",
        msg_weight_measuring_unit: "Weight measuring Unit",
        msg_activities: "Activities",
        msg_countries: "Countries",
        msg_item_code: "Item Code",
        msg_parent_id_code: "Parent Id",
        msg_parent_item_code: "Parent Item Code",
        msg_active: "active",
        msg_gs1_codes: "GS1 Codes",
        msg_egs_codes: "EGS Codes",
        msg_code_type: "Code Type",
        msg_code_name: "Code Name",
        msg_code_level_id: "Code Level id",
        msg_category: "Category",
        msg_details: "Details",
        msg_owned_by: "Owned by",
        msg_code_requests: "Code Requests",
        msg_code_id: "Code Id",
        msg_request_id: "Request Id",
        msg_parent_level_name: "Parent Level Name",
        msg_level_name: "Level Name",
        msg_request_creation_date: "Request Creation Date",
        msg_code_creation_date: "Code Creation Date",
        msg_create_new_code: "Create New Code",
        msg_channel: "Channel",
        msg_filters: "Filters",
        msg_register_credentials: "Register Credentials",
        msg_credentials: "Credentials",
        msg_quantity: "Quantity",
        msg_currency_sold: "Currency Sold",
        msg_exchange_rate: "Exchange Rate",
        msg_unit_price: "Unit Price",
        msg_total_sales_amount: "Sales Value",
        msg_discount_rate: "Discount Rate",
        msg_discount_amount: "Discount Amount",
        msg_rate: "Rate",
        msg_amount: "Amount",
        msg_tax_item: "Tax Item",
        msg_totals: "Total Sales Amount",
        msg_total_taxable_fees: "Total Taxable Fees",
        msg_value_difference: "Value Difference",
        msg_items_discount_after_taxes: "Discount (after taxes)",
        msg_net_total: "Net Total",
        msg_total_amount: "Total Amount",
        msg_totals_amount: "Totals Amount",
        msg_line_items_details: "Line Items Details",
        msg_total_tax_amount: "Tax Value",
        msg_total_tax_vat: "Total Tax (VAT)",
        msg_signed_invoice: "Signed Invoice",
        msg_confirm_invoice_submission_to_tax_authority:
            "Are you sure of the billing information as it will be sent to the tax authority?",
        msg_dashboard: "Dashboard",
        msg_send: "Send",
        msg_cancel: "Cancel",
        msg_available_products: "Internal Goods & Services",
        msg_statistics: "Statistics",
        msg_goods_and_services: "Goods & Services",
        msg_documents_states: "Documents States",
        msg_submissions_states: "Submissions States",
        msg_valid_documents_statistics: "Valid Documents Statistics",
        msg_EGP: "EGP",

        msg_login: "Login",
        msg_welcome_in: "Welcome in",
        msg_tenant_name: "Tenant name",
        msg_email: "Email",
        msg_all_required: "All fields required",
        msg_register: "Register",
        msg_package: "Package",
        msg_confirm_password: "Confirm password",
        msg_have_account: " Have account? ",
        msg_safe_address: "Smart Village - B115 - Cairo - Egypt",
        msg_name: "Your Name",
        msg_enquiry: "Enquiry",
        msg_have_no_account: " Have no account? ",

        msg_home_section1: ' Rabet for e-invoice linking services',
        msg_home_section1_subtitle: 'Subscribe to get your own e-invoice platform ',
        msg_prices: 'Prices',
        msg_sign_up: 'Sign up',

        msg_home_section2: 'Functions',
        msg_home_section2_1: 'Create, issue and send invoices, debit and credit notes services for subscribers with no accounting applications',
        msg_home_section2_2: 'Send invoices issued by accounting applications',
        msg_home_section2_3: 'Validate invoice data according to Standards',
        msg_home_section2_4: 'Invoices electronic signature',
        msg_home_section2_5: 'Send and receive invoices, debit and credit notes',
        msg_home_section2_6: 'Archive all transactions related to invoices',
        msg_home_section2_7: 'Availing performance reports for e-invoices',
        msg_home_section2_8: 'Support in product coding and registration in record time',

        msg_home_section3: 'Features',
        msg_home_section3_1: 'Each client will get his own platform to ensure data privacy and security',
        msg_home_section3_2: 'Integration with the e-invoice platform of the ETA for all merchants and companies',
        msg_home_section3_3: 'Easy to use with your PC only',
        msg_home_section3_4: 'Technical support during working hours',
        msg_home_section3_5: 'Ready to apply all tax laws and regulations whenever issued',
        msg_home_section3_6: 'Lowest invoice error rate',

        msg_home_section4_price: 'EGP / Annually',

        msg_home: 'Home',
        msg_contact: 'Contact Us',

        msg_footer_1: 'Powered by ',
        msg_footer_2: 'SAFE Company',
        msg_footer_3: 'Copyright © 2021. All rights reserved.',

        msg_lang: 'العربية',
        msg_terms: 'Terms and Conditions',
        msg_terms_1: `RABET platform for managing e-invoices services and integration services with the e-invoice system in the Arab Republic of Egypt, processing transactions and leasing software via the Internet, as well as all related professional services or other related services. All of the above are referred to as "services".
        The following are the terms and conditions for the use of these services, along with any amendments to them and any operational rules or policies that may be published from time to time by "RABET".
        `,
        msg_terms_2: 'First: Services and Support',
        msg_terms_3: '1. Services are provided in accordance with these terms and any operating policies that the company may establish; these are all referred to by the term "Agreement". The company may make changes to this agreement and these terms, and the continuous use of any services provided by the company is considered as an approval by the client of any of these changes. In addition, when using certain services, the client and the Company are subject to any published guidelines or rules that apply to these specific services, which may be published from time to time. ',
        msg_terms_4: '2. Services are only available to companies and individuals who can sign and register legally binding contracts under applicable law. Without limiting the foregoing, the Services are not available to individuals under the age of 18. If you do not qualify, please do not use the services.',
        msg_terms_5: "3. Rabet ensures that you have a platform of your own. This platform contains the data of the taxpayer, clients, goods and services. The platform has all guarantees of confidentiality of information as it can only be accessed through the authenticated users of your platform.",
        msg_terms_6: 'Second: Restrictions and Responsibilities',
        msg_terms_7: '1. This is an agreement for services under which the client has not been granted a license for any program under this agreement. Accordingly, the client undertakes not to do, directly or indirectly, the following:',
        msg_terms_8: '- Reverse engineering or decrypt the codes or any codes of the company.',
        msg_terms_9: '- Attempting to discover the code or source codes, the code or source codes of software objects, the infrastructure of the services, the ideas used, the algorithms, the basic services, or those included in the services, or any programs, software, documents, or data related to the services. This is all referred to by the term "software".',
        msg_terms_10: '- Modify, translate or create derivative works based on services or any program or software or copy (except for archival purposes), or distribute, pledge, assign or transfer rights or transfer them to services or any program.',
        msg_terms_11: '- Remove any notices or proprietary tags.',
        msg_terms_12: '2. The client acknowledges, undertakes and guarantees that he will only use the services in accordance with the privacy policies of the company, the owner of the RABET platform, as shown below or otherwise provided to the client and all laws in force in the Arab Republic of Egypt. The client hereby agrees to indemnify the company and avoid damage incurred by it against any damages, losses, liabilities, settlements or expenses.',
        msg_terms_13: 'Third: Termination of the contract',
        msg_terms_14: '1. Client may terminate this agreement at any time by sending an email to support@rabet-eg.com',
        msg_terms_15: '2. The company may terminate this agreement or services at any time in case of violating any of the policies with or without notice. The company does not bear any responsibility towards the client or any third party due to this termination.',
        msg_terms_16: '3. Upon termination for any reason, "RABET" may delete any data archived by the client within 90 days of the date of termination. All sections of this agreement that by their nature must remain in effect even after termination of the agreement will remain in effect after the termination of the agreement, and this includes limited or unlimited liability.',
        msg_terms_17: 'Fourth: Payment and Service Renewal',
        msg_terms_18: "1. Service condition: The client’s subscription period in the services starts upon the client’s acceptance of these conditions and ends as described above.",
        msg_terms_19: '2. Paying off:  The client agrees to pay all fees applicable to the services in effect at the time of registration, first use, or renewal, subject to these conditions, as they may be amended by the company, which owns the RABET platform from time to time.',
        msg_terms_20: '3. Cancellations and Refunds:  The platform does not provide a money-back guarantee for new account registrations under any circumstances.',
        msg_terms_21: '4. Piracy: "Piracy" and related activities are absolutely prohibited. "Hacking"  Includes, but is not limited to the following activities: unlawfully or without permission, accessing computers, accounts, or networks, hacking or attempting to breach security measures, port scans, intrusion scans, and other activities designed to assist Piracy.',
        msg_terms_22: '5. Anonymous Proxies:  We do not allow the use of anonymous proxy scripts on its servers. It can be very abusive to server resources, affecting all users on this server.',
        msg_terms_23: "6. Backups:  For the sake of its efficiencies and operational purposes, RABET will do from time to time back up data on its servers, but it is not obligated or obligated for the client to do so under these terms. It is the client's duty alone and his responsibility to backup the client's files or data on the RABET platform. Under any circumstances, the RABET platform will not be binding on anyone due to any damages of any kind or under any legal theory resulting from the loss of client files or data.",
        msg_terms_24: '7. Governing law:  These conditions are subject to the laws of the Arab Republic of Egypt without reference to the principles of conflict of laws. The client agrees that all disputes - if any - involving the company are exclusively subject to the jurisdiction of the Arab Republic of Egypt;  Provided, moreover, all claims against the company must be brought in a court in the Arab Republic of Egypt.',
        msg_terms_25: '4. Since "RABET" services are based on integration with the e-invoice system provided through the data center of the Egyptian Tax Authority, the "RABET" platform is not responsible by any mean for service failure or interruption happened by the Tax Authority and therefore there will be no financial penalties or subscription refund in case of any problems from Tax Authority side.',
        msg_phone: '+20 101 313 3546',
        msg_fax: '+20 235 371 216',
        msg_user_pending: 'User is pending admin confirmation',
        msg_user_not_found: 'User not found please try again',
        msg_company_not_found: 'Tenant not found',
        msg_contact_success: 'Sent successfully, Thanks',
        msg_contact_error: 'Please, Try again',
        msg_confirm_password_error: 'Confirm password does not match',
        msg_agree_terms: 'I agree to our ',
        msg_agree_terms_1: '',
        msg_wrong_mail: 'Wrong email',
        msg_wrong_mail_or_password: 'Wrong email or password',
        msg_verify_email_and_password: 'Verify email and password',
        msg_reset: 'Reset',

        msg_profile_credentials: 'Register Credentials',
        msg_client_id: 'Client ID',
        msg_client_secret: 'Client Secret',
        msg_profile_credentials_1: "Note: once you save your credentials you can't change them!!",
        msg_profile_credentials_2: 'If you need to update your credentials, send an email to support@rabet-eg.com',

        msg_profile_onboarding_1: 'Agent is used to sign documents and submit document files generated from ERP.',
        msg_profile_onboarding_2: 'A service account is a special kind of account used by an application (signing agent) in order to communicate with our backend system. After downloading service account copy it then navigate to config folder in Agent installation folder and paste it their.',
        msg_profile_onboarding_3: 'Config file used by (signing agent): After downloading config file copy it then navigate to config folder in Agent installation folder and paste it their.',

        msg_subscription_free: 'Try Rabet (14 Days)',
        msg_free_trial_start_today: 'Start today with our 14-day free trial',
        msg_free: 'Free',
        msg_subscription: 'Subscription',

        msg_change: 'Change',
        msg_extend_package: 'Renew Package Request',
        msg_download_template: 'Download then fill template',
        msg_import_excel: 'Import Excel File',
        msg_products_upload: 'Products will be uploaded: ',
        msg_addresses_upload: 'Addresses will be uploaded: ',
        msg_upload: 'Upload',
        msg_note_renew: 'Note: The remaining documents from the current package will be added to the new package',

        msg_instructions: 'Instructions',
        msg_instructions_title: 'إرشادات عامة للتسجيل في منظومة الفاتورة الإلكترونية',
        msg_instructions_intro: 'مقدمة',
        msg_instructions_paragraph1: 'تبنت وزارة المالية ومصلحة الضرائب المصرية خطوات عدة لتحقيق رؤية مصر 2030 وتحقيق التحول الرقمي في المنظومة المالية وتطوير المنظومة الضريبية، وإحدى تلك الخطوات هي تطبيق نظام الفاتورة الإلكترونية، وهي الخطوة الأولى الحقيقية للحكومة لدمج الاقتصاد غير الرسمي في القناة الشرعية للاقتصاد الرسمي، حيث سيتم إلزام مجتمع الأعمال بإصدار فاتورة إلكترونية في القانون الجديد الذي سيربط جميع الممولين على مستوى الجمهورية مع مصلحة الضرائب، ولكن ما هي الفاتورة الإلكترونية؟!',
        msg_instructions_paragraph2: 'الفاتورة الإلكترونية هي فاتورة أو مستند رسمي يثبت معاملات البيع، يتم إصدارها وتعديلها بصيغة إلكترونية وكذلك إرسالها للجهات المختصة، وعليه فإنه يمكن اعتبار الفاتورة الإلكترونية أنها نظام منخفض التكاليف لمعالجة المعاملات التي تستفيد من تكنولوجيا المعلومات لتحويل عملية إعداد الفواتير اليدوية والورقية إلى صيغة إلكترونية أكثر فاعلية في معالجة رسائل البيانات والمحافظة على السجلات.',
        msg_instructions_paragraph3: 'أهم ملامح منظومة الفاتورة الإلكترونية',
        msg_instructions_paragraph4: 'بينما أعلنت مصلحة الضرائب المصرية بوزارة المالية عن أهم ملامح المنظومة الجديدة التي بدأت في تطبيقها مطلع عام 2020، حددت بعض السمات للفاتورة الإلكترونية التي ستستخدمها الشركات المساهمة في المنظومة، وهي:',
        msg_instructions_paragraph5: '- رقم فريد للفاتورة الإلكترونية',
        msg_instructions_paragraph6: '- توحيد الشكل والمحتوى للفاتورة الإلكترونية',
        msg_instructions_paragraph7: '- كود موحد للسلع/المنتجات',
        msg_instructions_paragraph8: '- توقيع إلكتروني معتمد',
        msg_instructions_paragraph9: '- إخطار لحظي بالفاتورة الإلكترونية للطرفين؛ البائع والمشتري كليهما.',
        msg_instructions_paragraph10: '- حفظ الفاتورة مع إمكانية الاستعراض والطباعة.',
        msg_instructions_paragraph11: 'وكأي نظام فإن منظومة الفاتورة الإلكترونية تحتاج إلى اتباع عدة إجراءات من أجل التسجيل عليها وبدء العمل بها، وإليكم شرح مبسط لتلك الإجراءات:',
        msg_instructions_paragraph12: 'المستندات المطلوبة للتسجيل على المنظومة، وهي:',
        msg_instructions_paragraph13: '1. أصل وصورة بطاقة الرقم القومي أو جواز السفر بتاريخ ساري. ',
        msg_instructions_paragraph14: '2. أصل وصورة البطاقة الضريبية وشهادة تسجيل بالقيمة المضافة.',
        msg_instructions_paragraph15: '3. خطاب تفويض من الشركة للتسجيل في منظومة الفاتورة الإلكترونية محدد به:',
        msg_instructions_paragraph16: '- بيانات الشركة (عنوان الشركة، رقم التليفون الخاص بالشركة، الإيميل الخاص بالشركة، رقم تسجيل الشركة). ',
        msg_instructions_paragraph17: '- بيانات المفوض أو المدير المسؤول عن منظومة الفاتورة الإلكترونية بالشركة مُحَددًا به (الاسم باللغتين العربية والإنجليزية، الرقم القومي الموجود بالبطاقة الشخصية، رقم التليفون، الإيميل). ',
        msg_instructions_paragraph18: '*في حالة عدم حضور الممول وحضر عنه الوكيل فينبغي توفر كل ما سبق من المستندات مع أصل التوكيل بتاريخ ساري وصورة منه.',
        msg_instructions_paragraph19: 'تسليم مستندات التسجيل بمنظومة الفاتورة الإلكترونية',
        msg_instructions_paragraph20: 'تسليم المستندات التي تم إعدادها مسبقا،حيث يقوم مدير النظام بإرسال طلب التسجيل مرفقًا به مستندات التسجيل على العنوان eInvoice@eta.gov.eg   وذلك عبر الإيميل الرسمي للشركة، بحيث يقوم الموظف المسؤول بإخطار الممول أو المفوض بميعاد حضوره إلى مأمورية الضرائب التابع لها لمطابقة المستندات في حالة استيفائها والقيام بطلب ملف تعريف رقمي، أما في حالة نقصها يتم إرسال إخطار بالمستندات الناقصة عبر الإيميل، بعد ذلك يقوم الموظف المختص بالاطلاع على أصول المستندات وإجراء المطابقة بين أصول المستندات وما تم إرساله عبر البريد الإلكتروني وإعلامه بأنه سيتم تفعيل تسجيله بالمنظومة وإخطاره بذلك خلال 48 ساعة.',
        msg_instructions_paragraph21: 'إنشاء الملف الرقمي ومشاركة المعلومات',
        msg_instructions_paragraph22: 'يقوم موظف المصلحة المختص بتسجيل الممول وإنشاء الملف الرقمي على منظومة الفواتير الإلكترونية، بالإضافة إلى توفير دليل تعريفي شامل للممول بعد عملية تفعيل وتسجيل الملف الإلكتروني الخاص به، يتضمن شرح لمكونات المنظومة وندوات توعوية للرد بشكل مباشر على أي استفسارات. ',
        msg_instructions_paragraph23: 'استلام دعوة التسجيل بالبريد الإلكتروني',
        msg_instructions_paragraph24: ' يستقبل مدير النظام دعوة تسجيل الملف الرقمي من قبل الموظف المختص عبر البريد الإلكتروني ويكون مرفق به الرابط الذي سيتم من خلاله مواصلة إنشاء ملف تعريف المستخدم. ',
        msg_instructions_paragraph25: 'الحصول على شهادة التوقيع الإلكتروني',
        msg_instructions_paragraph26: 'وتلك الخطوة تتم بالتوازي مع الخطوات الأخرى، حيث يعد التوقيع الإلكتروني بمثابة التوقيع الرسميّ للمموّل، ويأخذ شكل رسالة مشفّرة، تتوافق مع اللوائح القانونية،  كما توفّر أعلى مستوى من التأكيد لهوية الموقّع.',
        msg_instructions_paragraph27: '*يمكن للممول الاشتراك في التوقيع الإلكتروني من خلال ',
        msg_instructions_paragraph27Link: 'شركة مصر للمقاصة والإيداع والقيد المركزي (M.C.D.R.) ',
        msg_instructions_paragraph27Link2: ' الشركة المصرية لخدمات التوقيع الإلكتروني وتأمين المعلومات (Egypt Trust)',
        msg_instructions_paragraph28: '*المستندات المطلوبة لاستخراج شهادة التوقيع الإلكتروني ',
        msg_instructions_paragraph29: '1. توقيع الطلب أو العقد من المدير المسؤول للمنشأة أو ممثلها القانوني حيث يكون له الحق علي التوقيع بموجب تفويض، ويكون طلب الحصول على الشهادة والتفويض موقعين من قبل المدير المسئول للمنشأة أو ممثلها القانوني ومصدقًا على توقيعاته بخاتم البنك المتعامل معه (توقيع مطابق).',
        msg_instructions_paragraph30: '2. تقديم الممثل القانوني أصول كافة المستندات والوثائق للاطلاع عليها وتتمثل في: ',
        msg_instructions_paragraph31: '- صورة من المستخرج الرسمي للسجل التجاري أو قرار الإنشاء/الإشهار.: ',
        msg_instructions_paragraph32: '- صورة من البطاقة الضريبية.',
        msg_instructions_paragraph33: '- صورة صحيفة الاستثمار أو الشركات أو عقد الشركة.',
        msg_instructions_paragraph34: '- صورة إثبات الشخصية للمدير المسؤول.',
        msg_instructions_paragraph35: 'إنشاء الملف الرقمي لمدير النظام',
        msg_instructions_paragraph36: 'يتم بعد ذلك إنشاء الملف الرقمي الخاص بمدير النظام من خلال ملء بعض المعلومات في شاشة إكمال الملف الشخصي ',
        msg_instructions_paragraph36_bold: 'الاسم الأول واسم العائلة بالعربية والإنجليزية، رقم الهاتف المحمول، الرقم القومي، عنوان الإيميل، اللغة المفضلة للتواصل',
        msg_instructions_paragraph36_2: 'بنفس الطريقة التي قام الموظف المختص بالمصلحة بإدراج تلك البيانات، ثم يتم إرسال كلمة مرور لمرة واحدة (OTP) للهاتف المحمول الخاص بمدير النظام، وأخيرا يقوم مدير النظام نيابةً عن الممول بكتابة كلمة المرور ثم التأكيد عليها مرةً أخرى، ثم النقر على زر الحفظ لاستكمال الخطوات. ',
        msg_instructions_paragraph37: 'انتهاء عملية التسجيل والانتقال لإعداد الإخطارات',
        msg_instructions_paragraph38: '- بعد اتباع كافة الخطوات السابقة تكون عملية التسجيل قد تمت.',
        msg_instructions_paragraph39: '- تقوم منظومة الفواتير الإلكتروني بتحويل مدير النظام تلقائيا على شاشة التسجيل لإعادة تسجيل الدخول.',
        msg_instructions_paragraph40: '- يقوم مدير النظام باستخدام نفس البريد الإلكتروني وكلمة المرور التي تم تعيينها في الخطوات السابقة والضغط على زر الدخول.',
        msg_instructions_paragraph41: '- ثم يقوم بالنقر على ملف الممول، والضغط علي زر إدارة الإخطارات.',
        msg_instructions_paragraph42: 'تظهر شاشة الإعدادات ويقوم مدير النظام من خلالها بإعداد واختيار التفضيلات الخاصة بالإخطارات (مثل وسيلة التواصل والفترات وأنواع الإخطارات) وحفظها.',
        msg_instructions_paragraph43: 'تسجيل نظام تخطيط موارد المؤسسة للممول "النظام المحاسبي"',
        msg_instructions_paragraph44: 'يقوم مدير النظام بتسجيل الدخول ثم النقر على ملف الممول، واختيار خانة الـ ERP والضغط علي زر التسجيل ليقوم نظام الفاتورة الإلكترونية بإظهار نموذج الإضافة لنظام الـ ERP التابع للممول ثم القيام بالآتي:',
        msg_instructions_paragraph45: '- ملئ البيانات مثل اسم النظام، ومدة الصلاحية المتاحة للنظام لتبادل المستندات من خلال التكامل.',
        msg_instructions_paragraph46: '- الضغط على زر الحفظ.',
        msg_instructions_paragraph47: '*يمكن للممول تسجيل أنظمة تخطيط موارد مؤسسية أخري إذا كانت متاحة له.',
        msg_instructions_paragraph56: '- بعد الحفظ، ستظهر شاشة الحصول على بيانات التسجيل والمصادقة للتكامل مع المنظومة، والتي تحتوي على: معرّف العميل ومفتاح سر ۱ ومفتاح سر۲، والتي ينبغي عليك نسخها والتأكد من الاحتفاظ بها قبل إغلاق هذه الشاشة.',
        msg_instructions_paragraph48: '- النقر فوق زر تم.',
        msg_instructions_paragraph49: '*تتيح منظومة الفاتورة الإلكترونية إضافة أكثر من مفوض بهذه الطريقة:',
        msg_instructions_paragraph50: '1. من نفس شاشة الممول يقوم مدير النظام باختيار خانة المستخدم ويقوم بالضغط على دعوة مستخدم. ',
        msg_instructions_paragraph51: '2. تظهر شاشة الدعوة ويقوم مدير النظام بإدخال بيانات الملف الشخصي للمفوض (الاسم الأول واسم العائلة باللغتين "العربية والإنجليزية – رقم الهاتف – الرقم القومي – الإيميل – فترة نشاط المستخدم وتحديد نوعه: مدير أو مندوب). ',
        msg_instructions_paragraph52: '3.الضغط على زر الحفظ.',
        msg_instructions_paragraph53: 'ثم يقوم النظام تلقائيا بإرسال دعوة للمستخدم الإضافي لاستكمال الخطوات وإنشاء الملف الرقمي كما تم توضيحه مسبقا.',
        msg_instructions_paragraph54: 'تكويد السلع والخدمات ',
        msg_instructions_paragraph55: 'تقوم مصلحة الضرائب المصرية في تنفيذ منظومة الفاتورة الإلكترونية على معايير عالمية لتكويد السلع والخدمات وتصنيفاتها، لضمان تبادل المستندات بطريقة معيارية موحدة ومنظمة تعمل على تقليل الخطأ وتوحيد تعريف السلع والخدمات بين الأطراف التجارية (البائع والمشتري)، ويوجد طريقتين للتكويد هما: ',
        msg_instructions_paragraph57: 'الأولى :  إذا  كانت اكواد سلع وخدمات شركتك وفقا لنظام التكويد العالمي GS1 ستقوم بتسجيل هذه الأكواد على منصه الفاتوره الالكترونيه للتأكد من تفعيل تلك الأكواد على قواعد بيانات منظومة الفاتورة الإلكترونية. ',
        msg_instructions_paragraph58: `الثانية :  إذا كانت اكواد سلع وخدمات شركتك اكواد داخلية  فلازم تتبع الخطوات التالية: `,
        msg_instructions_paragraph58_1: '1 – ربطها بنظام التصنيف السلعي GPC',
        msg_instructions_paragraph58_2: '2- تسجيل اكواد ال EGS على منصة الفاتورة الالكترونية والمكونة من الكود الداخلي بنظام GPC – رقم التسجيل – EG',
        msg_instructions_paragraph58_3: '3- والمصلحة هتراجع الأكواد دي علشان توافق عليها .',
        msg_instructions_paragraph58_4: '4- بإتمام موافقة المصلحة يتم استخدام اكواد ال EGS في إصدار الفاتورة الإلكترونية.',
        msg_instructions_paragraph58_5: `5-  و لو عندك سلعة أو خدمة ملهاش GPC يمكنك التواصل مع شركه GS1 لمساعدتك فى تحديد الكود الخاص بها مجانا , عن طريق إرسالك لإيميل  على الإيميل التالى
        einvoice@gs1eg.org`
        ,
        msg_instructions_paragraph58_6: 'وللمزيد قم بزيارة ',
        msg_instructions_paragraph58_7: ' المقدم من مصلحة الضرائب المصرية ',
        msg_instructions_paragraph59: 'ويمكنك البحث عن كود أي سلعة أو خدمة من خلال هذا الرابط ',
        msg_free_trial_start_today_note: 'Try Rabet on taxation test platform',

        msg_type_name: 'Type Name',
        msg_type_id: 'Type ID',

        "CLIENT-001": "Tenant name already exists",
        "CLIENT-002": "Error while creating a tenant",
        "CLIENT-003": "Error while creating a user for tenant",
        "CLIENT-004": "Values should be one of this, ['unit', 'activity', 'country', 'tax', 'taxSub', ''nonTaxable']",
        "CLIENT-005": "Tenant name not found",
        "CLIENT-006": "Tenant not found",
        "CLIENT-007": "Subscription request not found",
        "CLIENT-008": "Submission not found",
        "CLIENT-009": "User credentials do not belong to a tenant",
        "CLIENT-011": "Frontend application has submitted and invalid file",
        "CLIENT-012": "could not find specified address, try again later",
        "CLIENT-013": "Input payload validation failed",
        "CLIENT-014": "Tenant name id found",
        "CLIENT-015": "Document not found",
        "CLIENT-016": "You have exceeded max document submissions",
        "CLIENT-017": "Your subscription has ended",
        "CLIENT-018": "internal code already exists",
        "CLIENT-019": "A Subscription request already exists",
        "CLIENT-020": "Subscription does not exist with this id",
        "CLIENT-021": "Product with id <ID> does not exist",
        "CLIENT-022": "Product with id <ID> does not exist",
        "CLIENT-023": "Product with internal code <internalCode> already exists",
        "CLIENT-024": "Error while fetching a tenants users",
        "CLIENT-025": "Error while fetching a tenant's user",
        "CLIENT-026": "Error while creating a user for tenant",
        "CLIENT-027": "Can't delete your user",
        "CLIENT-028": "Error while deleting a tenant's user",
        "CLIENT-029": "Draft not found",
        "CLIENT-030": "Draft docx not found",
        "CLIENT-031": "Not Authorized",
        "CLIENT-032": "Error email already exist",
        "CLIENT-900": "BadRequest",
        "CLIENT-901": "BadArgument",
        "CLIENT-902": "NotFound",
        "CLIENT-903": "cannot retrieve ETAX access token",
        "CLIENT-904": "ETAX Client Credentials are not provided",
        "CLIENT-905": "BadStructure",
        "CLIENT-906": "IncorrectSubmitter",
        "CLIENT-907": "MaximumSizeExceeded",
        "CLIENT-908": "DuplicateSubmission",
        "CLIENT-909": "OperationPeriodOver",
        "CLIENT-910": "IncorrectState",
        "CLIENT-911": "ActiveReferencingDocuments",
        "CLIENT-912": "Forbidden",
        "CLIENT-913": "NotReady",
        "CLIENT-914": "Not Found",
        "CLIENT-915": "OperationExceedsLimit",
        "CLIENT-916": "ValidationError",
        "SERVER-001": "Error while creating a tenant",
        "SERVER-002": "Failed to send email for contact us",
        "SERVER-003": "ESP didn't enforce firebase and it is required",
        "SERVER-004": "credentials doesn't exist, please contact administrator",
        "SERVER-005": "config file doesn't exist, please contact administrator",
        "SERVER-006": "agent file doesn't exist, please contact administrator",
        "SERVER-007": "tenant package not found",
        "SERVER-008": "tenant subscription not found",
        "SERVER-009": "Error while fetching tenants' users",
        "SERVER-010": "Error while fetching a tenant's user",
        "SERVER-011": "Error while creating a user",
        "SERVER-012": "Error while updating a user",
        "SERVER-013": "Error while deleting a tenant's user",
        "SERVER-900": "Unauthorized",
        "SERVER-901": "Forbidden",
        "SERVER-902": "NotFound",
        "SERVER-903": "TooManyRequests",
        "SERVER-904": "InternalServerError",
        "SERVER-905": "NotImplemented",
        "SERVER-906": "ServiceUnavailable",
        "SERVER-907": "ConnectionError",
        "SERVER-908": "TimeoutError",
        "SERVER-999": "Unknown",

        msg_add_address: 'Branch Information',
        msg_add_receiver: 'Receiver Information',
        msg_company_full_name_help_text: 'According to your tax card',
        msg_item_code_good: "Good/Service Code",
        msg_upload_invoices_intro: 'The excel invoices template can be used to create your invoices in Excel format, upload and Rabet will send it to the Tax Authority',
        msg_full_invoice: 'Full Invoice',
        msg_simplified_invoice: 'Simplified Invoice',
        msg_remember_me: 'Remember me',

        msg_faq_section_intero_1: 'رابط هي منصة تعمل علي تقديم خدمات الربط والتوقيع الإلكتروني للفواتير مع منظومة الفاتورة الإلكترونية بمصلحة الضرائب المصرية.',
        msg_faq_section_intero_2: 'نقدم بعض الاسئلة الشائعة المتكررة بين عملاءنا لمساعدتك فى استخدام منصة رابط:',

        msg_faq_section_head_1: 'أسئلة شائعة خاصة بالاشتراك',
        msg_faq_section_head_1_ques_1: '1. ما هي أنواع الاشتراكات التي يتيحها رابط؟',
        msg_faq_section_head_1_ques_2: '2. كيف يتم تفعيل حسابك على رابط؟',
        msg_faq_section_head_1_ques_3: '3. هل متاح التسجيل لأكثر من مرة على الباقة التجريبية Free trial على رابط "50 فاتورة / إشعار لمدة 14 يوم مجانا"؟',
        msg_faq_section_head_1_ques_4: '4. ما الذي يحدث قرب انتهاء مدة التجربة المجانية "Free trial"؟',
        msg_faq_section_head_1_ques_5: '5. ماذا يحدث عند إقتراب نهاية مدة الباقة أو عدد الفواتير المتاحة فيها ؟',
        msg_faq_section_head_1_ques_6: '6. فى حال كنت على الباقة التجريبية وأردت الإشتراك فى باقة اخرى، هل يجب إعادة خطوات الإعدادات التى قد قمت بها ؟',
        msg_faq_section_head_1_ques_7: '7. ما الذي يمكنك فعله في حال نسيت كلمة المرور الخاصة بك؟',

        msg_faq_section_head_1_ans_1: 'عدد الفواتير والإشعارات',
        msg_faq_section_head_1_ans_1_1: '50 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_2: '500 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_3: '1,000 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_4: '3,000 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_5: '10,000 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_6: 'تجربة (مجانا)',
        msg_faq_section_head_1_ans_1_7: '2,500 ج. م',
        msg_faq_section_head_1_ans_1_8: '4,500 ج. م',
        msg_faq_section_head_1_ans_1_9: '12,000 ج. م',
        msg_faq_section_head_1_ans_1_10: '30,000 ج. م',
        msg_faq_section_head_1_ans_1_11: '14 يوم',
        msg_faq_section_head_1_ans_1_12: 'سنويا',

        msg_faq_section_head_1_ans_2: 'بعد القيام بمليء بيانات الاشتراك الخاصة بك والضغط على إنشاء حساب:',
        msg_faq_section_head_1_ans_2_1: '- فى حالة الباقة التجريبية: يتم إرسال إيميل يحمل كل تفاصيل اشتراكك لتأكيده ثم إرسال إيميل لإخطارك بموعد تفعيل حسابك وإرسال الملفات الخاصة باعدادات حسابك على رابط ، ثم يصلك خلال ساعتين إيميل التفعيل لتبدأ بالعمل على رابط.',
        msg_faq_section_head_1_ans_2_2: '- فى حالة الباقات الاخرى: يتم إرسال إيميل يحمل كل تفاصيل اشتراكك لتأكيده ثم إرسال إيميل لإخطارك بالمطالبة الخاصة بك وتفاصيل البنك ،وعند سداد قيمة اشتراكك يصل إيميل لتأكيد سدادك ثم يصلك خلال ساعتين إيميل التفعيل لتبدأ بالعمل على رابط.',
        msg_faq_section_head_1_ans_2_3: 'ملاحظة: علي العميل أن يتوجه بالمطالبة إلى البنك الأهلي المتحد "Ahli united bank" وذلك لسداد المبلغ المحدد للباقة التي تم اختيارها في حساب رابط : 0026 164362 003',

        msg_faq_section_head_1_ans_3: 'لا، لا يمكن حيث يسمح لكل عميل التسجيل مرة واحدة فقط علي الباقة التجريبية علي رابط، وفي حالة الرغبة في استمرار الحصول علي الخدمة يتم الإشتراك في أي باقة من الباقات التي يتيحها رابط.',
        msg_faq_section_head_1_ans_4: 'سوف يتم إخطارك بإيميل بقرب انتهاء باقة وايضا من خلال لوحة التحكم على منصة رابط لذا قبل انتهاء مدة الـ 14 يوم لابد من الاشتراك في إحدى الباقات التي يتيحها رابط من خلال البروفايل واختيار الاشتراك، ويتم سداد قيمة الاشتراك في حساب رابط رقم (0026 164362 003) في البنك الأهلي المتحد "Ahli united bank"، وذلك لضمان استمرار الخدمة.',

        msg_faq_section_head_2: 'أسئلة شائعة خاصة بالإعدادات "Onboarding"',
        msg_faq_section_head_2_ques_1: '1. ماهي الخطوات التي يجب اتباعها قبل البدء في تفعيل خدمات رابط مع مصلحة الضرائب؟',
        msg_faq_section_head_2_ques_2: '2. ما هي الخطوات التي يجب اتباعها لتفعيل خدمات رابط مع مصلحة الضرائب؟',
        msg_faq_section_head_2_ques_3: '3. كيف يعمل الـ agent الخاص برابط؟',
        msg_faq_section_head_2_ques_4: '4. كيف يمكنك الوصول للجهاز المتصل الـ token و الـ agent عن بعد إذا كنت بحاجة لذلك (إذا كنت تقوم برفع فواتيرك بصيغة json)؟',
        msg_faq_section_head_2_ques_5: '5. ماذا يحدث في حال تغيير الـ client secret والـ client id من جهة مصلحة الضرائب؟',

        msg_faq_section_head_2_ans_1: '',

        msg_faq_section_head_3: 'أسئلة شائعة خاصة بالتشغيل',
        msg_faq_section_head_3_1: 'الجزء الأول: البروفايل',
        msg_faq_section_head_3_2: 'الجزء الثانى: لوحة التحكم',
        msg_faq_section_head_3_ques_1: '1. كيفية إضافة الفروع الخاصة بك؟',
        msg_faq_section_head_3_ques_2: '2. كيفية إضافة بيانات العملاء الذين سيتم التعامل معهم؟',
        msg_faq_section_head_3_ques_3: '3. كيفية إضافة السلع والخدمات الخاصة بك؟',
        msg_faq_section_head_3_ques_4: '4. هل يتيح لك رابط تغيير كلمة المرور الخاصة بك؟',
        msg_faq_section_head_3_ques_5: '5. كيفية إنشاء وإرسال الفاتورة الإلكترونية لمصلحة الضرائب من خلال رابط؟',
        msg_faq_section_head_3_ques_6: '6. كيفية معرفة سبب رفض الفاتورة في حال الرفض "Invalid"؟',
        msg_faq_section_head_3_ques_7: '7. كيفية متابعة الإرسالات الخاصة بك؟',
        msg_faq_section_head_3_ques_8: '8. كيفية إنشاء إشعار خصم "دائن" وإشعار إضافة "مدين"؟',

        msg_faq_section_head_3_ans_1: '',

        msg_upload_invoices_intro_1: '(Please make sure to enter all data of e-invoices Excel template completely and correctly)',
        msg_simplified: 'simple',
        msg_full: 'full',
        msg_user_manual: 'User Manual',
        msg_contact_subtitle: ' (Call us at any time around the clock)',
    },
    ar: {
        msg_tax_amount_per_item: "مبلغ الضريبة لكل عنصر (المبلغ * الكمية)",
        msg_submit_and_delete: "إرسال و حذف",
        msg_item_with_internal_code: "العنصر ذو الرقم الداخلي",
        msg_is_already_exist: "موجود من قبل",
        msg_keep_field_empty_password: "اترك الحقل فارغًا, إذا كنت لا تريد تغيير الرقم السري",
        msg_UNAUTHORIZED: "غير مصرح",
        msg_authorized_message: "رجاء قم بالتواصل مع الدعم اذا كنت تشعر بان هناك خطأ",
        msg_user_form: 'نموذج المستخدم',
        msg_create_user: 'انشاء مستخم',
        msg_role: 'الوظيفة',
        msg_users: 'المستخدمين',
        msg_draft_info: 'بيانات المسودة',
        msg_print: 'طباعة',
        msg_draft_name: 'اسم المسودة',
        msg_draft_form: 'نموذج مسودة',
        msg_drafts: 'المسودات',
        msg_daily_status: 'الحالة اليومية',
        msg_schedule_documents: 'المستندات المجدولة',
        msg_schedule_documents_description: 'يمكنك تحقيق أقصى قدر من الكفاءة من خلال تخطيط وجدولة عمليات تحميل المستندات الخاصة بك مسبقًا',
        msg_title: "العنوان",
        msg_schedule_upload_form: "استمارة المستندات المجدولة",
        msg_schedule_documents_due_date_month_not_match: 'تأكد من أن جميع المستندات تشترك في نفس تاريخ الاستحقاق الشهري',
        msg_scheduled_upload: 'المستندات المجدولة',
        msg_update_status_date: 'تاريخ تحديث الحالة',
        msg_sent_date: 'تاريخ الإرسال',
        msg_documents_per_day: 'الوثائق لكل يوم',
        msg_due_date: 'تاريخ الاستحقاق',
        msg_are_u_sure_u_want_to_cancel_this_submission: 'هل أنت متأكد أنك تريد إلغاء هذه الإرسالية؟',
        msg_are_u_sure_u_want_to_start_this_service: 'هل أنت متأكد أنك تريد بدأ هذه الخدمة يدويا',

        msg_proceed: 'متابعة',
        msg_canceled_successfully: 'تم الإلغاء بنجاح',
        msg_run_scheduler_manually: 'تشغيل خدمة الإرسال يدويا',
        msg_service_started_successfully: 'نم تشغيل الخدمة بنجاح',
        msg_save_as_draft: 'حفظ كمسودة',
        msg_function_longer_supported_by_ETA: "هذه الخاصية تم وقفها بعد حذفها من طرف مصلحة الضرائب",
        msg_invoice_items: 'اصناف الفاتورة',
        msg_invoice_items_is_empty: 'لا يوجد اصناف في الفاتورة',
        msg_first_name: 'الاسم الاول',
        msg_last_name: 'اسم العائلة',
        msg_receiver_id_required: 'الحقل مطلوب للفواتير قيمتها اكثر من 50,000',
        msg_job_title: 'المسمى الوظيفي',
        msg_thanks_for_registering: 'شكرا على التسجيل',
        msg_registered_free_package_instruction: 'سيتم ارسال بريد الكتروني عند الانتهاء من اجراءات تفعيل الحساب.',
        msg_registered_paid_package_instruction: 'تم راسال بريد الكتروني بمعلومات الدفع للحزمة التي تم اختيارها.',
        msg_delete_confirmation: 'هل انت متاكد انك تريد حذف هذا العنصر؟',
        msg_edit: 'تعديل',
        msg_click_to_know_invalidity: 'اضغط هنا لمعرفة سبب الرفض',
        msg_yes: "نعم",
        msg_no: "لا",
        msg_cancel_subscription_request: "هل تريد الغاء طلب الاشتراك ؟",
        msg_invoice_or_note_limit: 'اقصى عدد من الفواتير/الإشعارات',
        msg_duration: 'المدة',
        msg_price: "السعر",
        msg_purchase_order: "رقم طلب الشراء (PO)",
        msg_subscriptions: "الاشتراكات",
        msg_validating_invoices: 'التحقق من الفواتير',
        msg_deleted_successfully: 'تم الحذف',
        msg_submitted_successfully: 'تم الإرسال',
        msg_total_invoices: 'عدد الفواتير الكلية',
        msg_valid_invoices: 'عدد الفواتير الصحيحة',
        msg_invalid_invoices: 'عدد الفواتير الغير صحيحة',
        msg_documents_and_notes: 'فواتير/إشعارات',
        msg_show_items: 'عرض المنتجات',
        msg_show_errors: 'عرض الأخطاء',
        msg_invoices_and_notes_items: 'منتجات الفاتورة/الإشعار',
        msg_invoices_and_notes: 'الفاتورة/الإشعار',
        msg_errors: 'الأخطاء',
        msg_failed_to_load_issuer_details: 'فشل في تحميل بيانات الممول',
        msg_failed_to_load_receivers_details: 'فشل في تحميل بيانات العملاء',
        msg_failed_to_load_products_details: 'فشل في تحميل بيانات المنتجات',
        msg_upload_file_first: 'قم برفع ملف اولا. أو تأكد بأن الملف يحتوي على الsheets المذكورة في التعليمات',
        msg_convert_excel_file: '1/5 تغيير صيغة الملف',
        msg_loading_issuer_and_receivers_details: '2/5 تحميل بيانات الممول والعملاء',
        msg_loading_products_details: '3/5 تحميل بيانات المنتجات',
        msg_loading_default_values: '4/5 تحميل القيم الإبتدائية',
        msg_computing: '5/5 تنفيذ الحسابات',
        msg_invoice_sheet_not_found: "لم يتم العثور على ملف الفواتير او المنتجات في ملف الEXCEL",
        msg_maximum_number_of_invoices: 'أقصى عدد للفواتير في الإرسالية الواحدة 50 فاتورة داخل ملف ال Excel الواحد لضمان قبولها.',
        msg_excel_contains_2_sheets: "يجب أن يحتوي ملف ال Excelعلى 2 sheets, الأول خاص بالفواتير (invoices) ويتم وضع تفاصيل الفاتورة مثل بيانات العملاء والممول ونوع الفاتورة ...الخ أما الأخر خاص بالأصناف (items) ويتم وضع بيانات الأصناف مثل قيم السلعة وكود السلعة ... الخ.",
        msg_specify_receiver_products_internal_id: "يكتفى بكتابة كود العميل او كود السلعة/الخدمة فى حال إدخالها مسبقا فى البروفايل الخاص بك. وإلا فإنك تحتاج إلى ملئ كافة الحقول فى ملف ال .Excel",
        msg_first_option_full_invoices: "( يتم إرسال فاتورة كاملة بجميع الحسابات المطلوبة )",
        msg_second_option_simplified_invoices: "( يتم إرسال البيانات الأساسية وتقوم منظومة رابــط باستكمال الحسابات المطلوبة )",
        msg_download_invoices_template: 'تنزيل نموذج الفواتير',
        msg_upload_invoices: 'رفع الفواتير',
        msg_options: 'الاختيارات',
        msg_convert: 'أعداد البيانات',
        msg_upload_documents: 'رفع الفواتير (Excel)',
        msg_duplicating_line_item: "المنتج مضاف مسبقا, قم بتعديل المنتج في الفاتورة بدلا من إعادة إضافته",
        msg_eta_profile_category: 'مصلحة الضرائب',
        msg_rabet_profile_category: 'رابط',
        msg_faq_section: 'الأسئلة الشأئعة',
        msg_profile_settings_env_helper_text: 'تواصل مع الدعم إذا كنت بحاجة لتغير بيئة العمل.',
        msg_profile_settings: 'البيانات الشخصية',
        msg_export_as_csv: 'تحميل الفواتير بصيغة Excel',
        msg_production_helper_text: 'يتم اختيارها في أغلب الحالات.',
        msg_preproduction_helper_text_1: 'قم بإختيار',
        msg_preproduction_helper_text_2: 'إذا كنت بحاجة لاختبار المنظومة أولا من حيث (إرسال الفواتير, التكامل باستخدام الEXCEL, التوقيع الإلكتروني, .. إلخ). مع العلم, البيئة التجربية تحتاج إلى خطوات اكثر وتنزيل شهادة وصول مخصصة لذلك.',
        msg_tenant_name_helper_text: 'يتم استخدامه أثناء تسجيل الدخول, وهو مشابه لاسم المؤسسة (organization name)',
        msg_company_name: 'اسم الشركة',
        msg_phone_number: 'رقم الهاتف',
        msg_show_documents: 'عرض الفواتير',
        msg_submissions_rabet: 'الإرسالات(رابط)',
        msg_submissions: 'الإرسالات',
        msg_waiting_for_signing: 'بإنتظار التوقيع',
        msg_processing: "قيد المعالجة",
        msg_production: 'الأساسية',
        msg_preproduction: 'التجربية',
        msg_server_env: 'بيئة العمل',
        msg_company_registration_number_help_text: 'رقم التسجيل الضريبي للممول (وفقا لبطاقتك الضريبية), تأكد من صحة الرقم لإرسال الفواتير بنجاح',
        msg_notification_preferences: 'الإخطارات (الإدارة)',
        msg_notification_chanel: 'قم بتغير وسيلة الاتصال إلى ERP',
        msg_primary_erp: 'نظام ERP رئيسي',
        msg_ensure_primary_erp: 'تأكد من تحديد خيار ERP الأساسي',
        msg_package_request_sent_successfully: 'Package Request Sent successfully',
        msg_download_failed: 'فشل التحميل',
        msg_new_copy_of_invoice: 'نسخة جديدة من الفاتورة',
        msg_copied_to_clipboard: 'تم النسخ إلى الحافظة',
        msg_my_codes: 'أكوادي',
        msg_dashboard_submission_id: 'كود الطلبية (رابط)',
        msg_dashboard_submission_uuid: 'كود الطلبية (رابط)',
        msg_submission_uuid_eta: 'حافظة الفواتير (ETA)',
        msg_edit_document: 'تعديل المستند',
        msg_documents_count: 'عدد المستندات',
        msg_item_name: 'اسم الصنف',
        msg_receiver_internal_code: 'الكود الداخلي للعميل',
        msg_profile_title_meta: 'البروفايل | رابط لخدمات ربط الفاتورة الإلكترونية',
        msg_dashboard_title_meta: 'لوحة التحكم | رابط لخدمات ربط الفاتورة الإلكترونية',
        msg_home_title_meta: 'الرئيسية | رابط لخدمات ربط الفاتورة الإلكترونية',
        msg_subscribe: 'الإشتراك',
        msg_days: 'يوم',
        msg_remaining_documents: 'الفواتير/الإشعارات المتبقية',
        msg_remaining_days: 'الأيام المتبقية',
        msg_decline_cancellation: 'رفض الإلغاء',
        msg_decline_rejection: 'إلغاء الرفض',
        msg_reject: 'رفض',
        msg_all: 'الكل',
        msg_credit_note: 'إشعار دائن',
        msg_debit_note: 'إشعار مدين',
        msg_all_documents: 'الكل',
        msg_documents_received: 'المستلم',
        msg_documents_sent: 'المرسل',
        msg_code_active_yes: 'مفعل',
        msg_code_active_no: 'غير مفعل',
        msg_notification_type_delegation_invite: 'دعوة الوفد',
        msg_notification_type_otp: 'كود OTP',
        msg_notification_profile_data_validation: 'تحقق من البيانات الشخصية',
        msg_notification_type_generic_notification: 'إخطار عام',
        msg_notification_type_receive_download_ready: 'التحميل جاهز',
        msg_notification_type_document_received: 'تم استلام المستند',
        msg_notification_type_document_validated: 'تم التحقق من صحة المستند',
        msg_notification_type_document_cancelled: 'تم الغاء المستند',
        msg_notification_type_document_rejected: 'تم رفض المستند',
        msg_package_expired_yes: 'نعم',
        msg_package_expired_no: 'لا',
        msg_package_status_in_progress: 'قيد التنفيذ',
        msg_package_status_complete: 'اكتملت',
        msg_package_status_error: 'خطأ',
        msg_package_status_deleted: 'حذفت',
        msg_package_type_full: 'كاملة',
        msg_package_type_summary: 'ملخص',
        msg_package_format_csv: 'CSV',
        msg_package_format_xml: 'XML',
        msg_package_format_json: 'JSON',
        msg_mixed: 'مختلط',
        msg_structure_invalid: 'غير صالح',
        msg_pending: 'معلق',
        msg_you_should_specify_rate_or_amount: 'يجب إدخال القيمة أو المعدل',
        msg_credit_note_submission_total_error: 'المجموع في اشعار الخصم يجب ان يكون أقل من المجموع الكلي للمستند المراد تعديله: ',
        msg_debit_note_submission_total_error: 'المجموع في اشعار الإضافة يجب ان يكون أكثر من المجموع الكلي للمستند المراد تعديله: ',
        msg_or_create_new_item: 'أو إنشاء صنف جديد',
        msg_email_or_password_is_wrong: 'البريد أو كلمة المرور خاطئة',
        msg_eta_dashboard: 'لوحة تحكم مصلحة الضرائب',
        msg_navigate_to_eta_profile: 'الذهاب للوحة التحكم',
        msg_ERP: 'تخطيط موارد المؤسسات(ERP)',
        msg_register_erp_or_edit_one: 'تسجيل ERP أو تعديله',
        msg_callback_url: 'رابط الاتصال',
        msg_copy_and_paste_in_callback_url_field: 'قم بكتابة البيانات في حقل رابط الاتصال',
        msg_copy_and_paste_in_api_key_field: 'قم بكتابة البيانات في حقل مفتاح التشغيل',
        msg_api_key: 'مفتاح التشغيل',
        msg_notifications_settings: 'إعدادات الإخطارات',
        msg_view_invoice: 'عرض الفاتورة',
        msg_view_response: 'عرض مراجعة المصلحة',
        msg_accepted: 'مقبول',
        msg_failed: 'مرفوض',
        msg_eta_submission_response: 'مراجعة الإرسالية مصلحة الضرائب',
        msg_eta_document_response: 'مراجعة مصلحة الضرائب',
        msg_created_at: 'تاريخ الإنشاء',
        msg_updated_at: 'تاريخ النعديل',
        msg_dashboard_document_id: 'كود الفاتورة (رابط)',
        msg_eta_response: 'المراجعة من مصلحة الضرائب',
        msg_submitted_documents: 'الفواتير المرسلة',
        msg_default_issuer: 'الفرع المصدر للفاتورة',
        msg_delete: 'حذف',
        msg_invoice_or_note: 'فاتورة / إشعار',
        msg_signing_agent_file_app: 'برنامج التوقيع الالكتروني',
        msg_config_file: 'ملف الإعدادات',
        msg_service_account_file: 'ملف حساب الخدمة',
        msg_service_account: 'حساب الخدمة',
        msg_onboarding: 'إعدادات التوقيع الإلكترونى',
        msg_or_create_new_issuer: 'أو إنشاء مصدر (فرع) جديد',
        msg_or_create_new_receiver: 'أو إنشاء مستلم جديد',
        msg_available_issuers: 'عناوين  المصدرين (الفروع) المسجلة',
        msg_available_receivers: 'عناوين المستقبلين المسجلة',
        msg_package_subscription_status: 'حالة الإشتراك',
        msg_documents_charged: 'الفواتير المتبقية',
        msg_days_passed: 'الايام المتبقية',
        msg_verify: 'تحقق',
        msg_confirm: 'تاكيد',
        msg_change_password: 'تغير كلمة المرور',
        msg_password: 'كلمة المرور',
        msg_forget_your_password: 'نسيت كلمة المرور؟',
        msg_password_reset: 'اعادة رمز المرور',
        msg_logo_title: 'لوحة تحكم الفاتورة الإلكترونية',
        msg_english: 'English',
        msg_arabic: 'عربي',
        msg_profile: 'البروفايل',
        msg_logout: 'تسجيل الخروج',
        msg_no_recent_notification: 'لا يوجد إشعارات',
        msg_see_more: 'المزيد',
        msg_create_document: 'إنشاء فاتورة (Manual)',
        // msg_documents: 'الوثائق', 
        msg_documents: 'الفواتير والإشعارات',
        msg_document_reports: 'تقارير الفواتير والإشعارات',
        msg_document_reports_etax: 'تقارير الفواتير والإشعارات (مصلحة الضرائب)',
        msg_document_reports_rabet: 'تقارير الفواتير والإشعارات (رابط)',
        // msg_packages: 'الحزم',
        msg_packages: 'الفواتير المجمعة',
        msg_types: 'أنواع الفواتير',
        msg_notifications: 'الإخطارات',
        msg_pubsub: 'فواتير معلقة',
        msg_codes: 'الاكواد',
        msg_item_codes: 'اكواد المنتجات (إستعلام)',
        msg_invoice: 'فاتورة',
        msg_export_invoice: 'فاتورة تصدير',
        msg_invoice_credit: 'إشغار دائن',
        msg_invoice_debit: 'إشعار مدين',
        msg_export_invoice_credit: 'إشعار دائن تصدير',
        msg_export_invoice_debit: 'إشعار مدين تصدير',
        msg_submission: 'الطلب',
        msg_issuance_date: 'تاريخ الإصدار',
        msg_submission_date: 'تاريخ تقديم الطلب',
        msg_issuer: 'الممول',
        // msg_issuer: 'البائع',
        msg_issuer_from: 'الممول (من)',
        // msg_issuer_from: 'البائع (من)',
        // msg_receiver: 'المشتري',
        msg_receiver: 'العميل',
        // msg_receiver_to: 'المشتري (الى)',
        msg_receiver_to: 'العميل (الى)',
        msg_line_items: 'الأصناف',
        msg_order: 'الطلبية',
        msg_order_details: 'تفاصيل الطلب',
        msg_payment: 'الدفع',
        msg_delivery_information: 'معلومات التوصيل',
        msg_signatures: 'التوقيع',
        msg_validations: 'التحقق من صحة البيانات',
        msg_download: 'تنزيل',
        msg_name: 'الاسم',
        msg_registration_number: 'رقم التسجيل الضريبى',
        msg_type: 'النوع',
        msg_sub_type: 'النوع الفرعي',
        msg_address: 'العنوان',
        msg_addresses: 'عناوين الفروع',
        msg_tax_activity_code: "رقم النشاط الضريبي",
        //material-table-localization
        msg_of: "من",
        msg_first_page: "الصفحة الاولى",
        msg_previous: "السابق",
        msg_next: "التالي",
        msg_last_page: "الصفحة الأخيرة",
        msg_rows: "صفوف",
        msg_search: "بحث",
        msg_no_records: "لا يوجد بيانات",
        msg_actions: "التحكم",
        msg_refresh: "تحديث",
        msg_internal_code: "الرقم الداخلي للسلعة/الخدمة",
        msg_internal_id: "الرقم الداخلي للفاتورة",
        msg_internal_items: "السلع و الخدمات الداخلية",
        msg_purchase_order_description: "وصف طلب الشراء",
        msg_purchase_order_reference: "مرجع طلب الشراء",
        msg_proforma_invoice_number: "رقم الفاتورة المبدئية",
        msg_sales_order_description: "وصف طلب المبيعات",
        msg_sales_order_reference: "مرجع طلب المبيعات",
        msg_bank_account_iban: "الحساب المصرفي IBAN",
        msg_swift_code: "السويفت كود",
        msg_bank_account_no: "رقم الحساب البنكي",
        msg_bank_name: "اسم البنك",
        msg_payment_terms: "شروط الدفع",
        msg_bank_address: "عنوان البنك",

        msg_packaging: "التعبئة",
        msg_delivery_method: "طريقة التسليم و وسيلة النقل",
        msg_net_weight_kg: "الوزن الصافي (كجم)",
        msg_gross_weight_kg: "الوزن الإجمالي (كجم)",
        msg_country_of_origin: "بلد المنشأ",
        msg_export_port: "ميناء التصدير",
        msg_validity_date: "تاريخ الصلاحية",
        msg_delivery_terms: "شروط التسليم",
        msg_signed_by: "توقيع",

        msg_internal_id_helper_text:
            "هذا الرقم يمكن استخدامه لسهولة الوصول للفاتورة داخل مؤسستك",
        msg_local_time_zone: "التوقيت المحلي",
        msg_date: "التاريخ",
        msg_date_time_issued: "تاريخ وتوقيت الإصدار",
        msg_service_delivery_date: "تاريخ تسليم الخدمة",
        // msg_document_type: 'نوع الوثيقة',
        msg_document_type: "نوع المستند",
        // msg_document_type_version: 'نسخة الوثيقة',
        msg_document_type_version: "نسخة الفاتورة",
        msg_basic_information: "المعلومات الأساسية",
        msg_confirmation: "التأكيد",
        msg_issuer_and_receiver: "المصدر والمستلم",
        msg_issuer_address: "الفروع",
        msg_issuer_addresses: "عناوين الفروع",

        msg_receiver_address: "عنوان العميل",
        msg_receiver_addresses: "بيانات العملاء",
        msg_branch_id: "رقم الفرع",
        msg_id: "الهوية",
        msg_back: "رجوع",
        msg_submit: "إرسال",
        msg_add: "إضافة",
        msg_bulk_add: "إضافة بالجملة",
        msg_save: "حفظ",
        msg_remove: "حذف",
        msg_business: "اعمال",
        msg_person: "شخص",
        msg_foreigner: "اجنبي",

        msg_country: "الدولة",
        msg_governate: "المحافظة",
        msg_city: "المدينة",
        msg_building_number_or_name: "رقم / اسم المبنى",
        msg_room_number: "رقم الغرفة",
        msg_floor_number: "رفم الطابق",
        msg_street: "اسم الشارع",
        msg_landmark: "علامة مميزة (شارع2)",
        msg_additional_information: "معلومات إضافية",
        msg_version: "الاصدار",
        msg_issuer_id: "رقم الممول",
        // msg_issuer_id: 'رقم البائع',
        msg_issuer_name: "اسم الممول",
        // msg_issuer_name: 'اسم البائع',
        msg_receiver_id: "التسجيل الضريبى للعميل",
        // msg_receiver_id: 'رقم المشتري',
        msg_receiver_name: "اسم العميل",
        // msg_receiver_name: 'اسم المشتري',
        msg_submission_id: "رقم حافظة الفواتير",
        // msg_submission_id: 'رقم الطلب',
        msg_uuid: "رقم المستند",
        msg_eta_document_uuid: "رقم المستند (مصلحة الضرائب)",
        msg_status: "الحالة",
        msg_invoice_status: "حالة المستند",
        msg_net_amount: "الإجمالي",
        msg_date_time_received: "تاريخ الاستلام",
        msg_date_time_delivered: "تاريخ التسليم",
        msg_date_time_created: "أنشئت في",
        msg_cancel_or_reject: "إلغاء/رفض",
        msg_credit_or_debit: "اشعار مدين/دائن",
        // msg_document_count: 'عدد الوثائق',
        msg_document_count: "عدد الفواتير",
        msg_submission_details: "تفاصيل الطلب",
        msg_package_id: "كود التقرير",
        msg_format: "الصيغة",
        msg_expired: "انتهت الصلاحية",
        msg_deletion_date: "تاريخ الحذف",
        msg_requestor_taxpayer_RIN: "رقم تسجيل مقدم الطلب",
        msg_requestor_name: "اسم صاحب الطلب",
        msg_requestor_rin: "الرقم الضريبي لصاحب الطلب",
        msg_request_package_file: "إنشاء عرض مجمع للفواتير",
        msg_package_requests: `تقارير الفواتير المرسلة
        (يتم الإستعلام من خادم مصلحة الضرائب)
        `,
        msg_valid: "مقبولة",
        msg_invalid: "غير مقبولة",
        msg_canceled: "ملغى",
        msg_rejected: "مرفوض",
        msg_submitted: "ارسلت",
        msg_cancellation_request: 'طلب الغاء',
        msg_rejection_request: 'طلب رفض',
        // msg_document_types: 'نوع الوثائق',
        msg_document_types: "نوع الفواتير",
        msg_from: "من",
        msg_to: "الى",
        msg_receiver_type: "نوع العميل",
        // msg_receiver_type: 'نوع البائع',
        msg_id_code: "كود",
        msg_description: "الوصف",
        msg_active_from: "مفعل من",
        msg_active_to: "مفعل إلى",
        msg_view: "عرض",
        msg_value: "القيمة",
        // msg_document_workflow_parameters: 'معاملات الوثيقة',
        msg_document_workflow_parameters: "معاملات الفاتورة",
        msg_code: "الكود",
        msg_taxes: "الضرائب",
        msg_sub_taxes: "الضرائب الفرعية",
        msg_non_taxable: "الغير خاضع للضريبة",
        msg_units: "الوحدات",
        msg_weight_units: "وحدات الوزن",
        msg_tax_code: "أكواد الضرائب",
        msg_unit_type: "نوع الوحدة",
        msg_measuring_unit: "وحدة القياس",
        msg_weight_quantity: "كمية الوزن",
        msg_weight_measuring_unit: "وحدة قياس الوزن",
        msg_activities: "النشاطات",
        msg_countries: "الدول",
        msg_item_code: "كود المنتج",
        msg_parent_id_code: "كود المستوى الاعلى",
        msg_parent_item_code: "كود المستوى الاعلى للمنتج",
        msg_active: "نشط",
        msg_gs1_codes: "أكواد GS1",
        msg_egs_codes: "أكواد EGS",
        msg_code_type: "نوع الكود",
        msg_code_name: "اسم الكود",
        msg_code_level_id: 'كود المستوى',
        msg_category: "الفئة",
        msg_details: "التفاصيل",
        msg_owned_by: "مملوكة من قبل",
        msg_code_requests: "طلبات الأكواد",
        msg_code_id: "رقم الكود",
        msg_request_id: "كود الطلب",
        msg_parent_level_name: "اسم مستوى الأب",
        msg_level_name: "اسم المستوى",
        msg_request_creation_date: "توقيت إنشاء الطلب",
        msg_code_creation_date: "توقيت إنشاء الكود",
        msg_create_new_code: "إنشاء كود جديد",
        msg_channel: "القناة",
        msg_filters: "المحددات",
        msg_register_credentials: "تسجيل بيانات الإشترك",
        msg_credentials: "بيانات الإشتراك",
        msg_quantity: "الكمية",
        msg_currency_sold: "العملة",
        msg_exchange_rate: "سعر الصرف",
        msg_unit_price: "سعر الوحدة",
        msg_total_sales_amount: "قيمة المبيعات",
        msg_discount_rate: "معدل الخصم",
        msg_discount_amount: "قيمة الخصم",
        msg_rate: "المعدل",
        msg_amount: "القيمة",
        msg_tax_item: "الضريبة",
        msg_totals: "إجمالى قيمة المبيعات",
        msg_total_taxable_fees: "إجمالي الرسوم الخاضعة للضريبة",
        msg_value_difference: "فرق القيمة",
        msg_items_discount_after_taxes: "خصم الأصناف (بعد الضريبة)",
        msg_net_total: "صافي الإجمالي",
        msg_total_amount: "المبلغ الإجمالي",
        msg_totals_amount: "المبلغ الاجمالي",
        msg_line_items_details: "تفاصيل البنود",
        msg_total_tax_amount: "القيمة الضريبية",
        msg_total_tax_vat: "مجموع الضريبة (القيمة المضافة)",
        msg_signed_invoice: "توقيع الفاتورة",
        msg_confirm_invoice_submission_to_tax_authority:
            "هل أنت متأكد من بيانات الفاتورة حيث سيتم إرسالها إلى مصلحة الضرائب",
        msg_dashboard: "لوحة التحكم",
        msg_send: "إرسال",
        msg_cancel: "إلغاء",
        msg_available_products: "السلع و الخدمات الداخلية",
        msg_statistics: "الاحصائيات",
        msg_goods_and_services: "السلع و الخدمات",
        msg_documents_states: "احصائية حالة الفواتير",
        msg_submissions_states: "احصائية حالة الإرسالات",
        msg_valid_documents_statistics: "الاحصائية الضريبية للفواتير المقبولة",
        msg_EGP: "ج.م",

        msg_welcome_in: "مرحبا بك في",
        msg_login: "تسجيل دخول",
        msg_tenant_name: "اسم المشترك",
        msg_email: "البريد الإلكتروني",
        msg_all_required: "يجب ملىء كل البيانات",
        msg_register: "إنشاء حساب",
        msg_package: "نوع الإشتراك",
        msg_confirm_password: "تأكيد كلمة المرور",
        msg_have_account: " لديك حساب؟ ",
        msg_contact_us: "تواصل معانا",
        msg_safe_address: " القرية الذكية- مبنى (B115) محافظة القاهرة- مصر",
        msg_name: "الإسم",
        msg_enquiry: "اكتب إستفسارك",
        msg_have_no_account: " ليس لديك حساب؟ ",

        msg_home_section1: ' رابط لخدمات ربط الفاتورة الإلكترونية',
        msg_home_section1_subtitle: 'أحصل علي منصتك الخاصة والمؤمنة لإدارة فواتيرك الإلكترونية',
        msg_prices: 'الأسعار',
        msg_sign_up: 'الإشتراك',

        msg_home_section2: 'الوظائف',
        msg_home_section2_1: 'إصدار و إرسال الفواتير وإشعارات الخصم والإضافة للممولين الذين ليس لديهم أنظمة محاسبية إلكترونية',
        msg_home_section2_2: 'إرسال الفواتير الصادرة من الأنظمة المحاسبية الإلكترونية',
        msg_home_section2_3: 'التحقق من صحة بيانات الفاتورة ومطابقتها للمعايير',
        msg_home_section2_4: 'توقيع الفاتورة إلكترونيا ',
        msg_home_section2_5: 'إرسال واستقبال الفواتير وإشعارات الخصم والإضافة الخاصة بالممول',
        msg_home_section2_6: 'إمكانية أرشفة كافة المعاملات الخاصة بالممول والتي تمت من خلال المنظومة',
        msg_home_section2_7: 'إنشاء وعرض تقارير أداء خاصة بالممول للمعاملات التي تمت على المنظومة',
        msg_home_section2_8: 'مساعدة في تكويد المنتجات و التسجيل في مصلحة الضرائب في وقت قياسي',

        msg_home_section3: 'المزايا',
        msg_home_section3_1: 'منصة خاصة بكل عميل لضمان خصوصيةالبيانات وأمنها',
        msg_home_section3_2: 'التكامل مع منظومة الفاتورة الإلكترونية الخاصة بمصلحة الضرائب المصرية لكافة التجار والشركات',
        msg_home_section3_3: 'سهولة الاستخدام وذلك من خلال حاسبك الشخصي فقط',
        msg_home_section3_4: 'الدعم الفني خلال ساعات العمل الرسمية',
        msg_home_section3_5: 'جاهزية تطبيق تعديلات القوانين والقرارات الضريبية متى وجدت',
        msg_home_section3_6: 'أقل نسبة خطأ للفواتير',

        msg_home_section4_price: 'ج.م/سنويا',

        msg_home: 'الرئيسية',
        msg_contact: 'تواصل معنا',

        msg_footer_1: 'مدعوم بواسطة ',
        msg_footer_2: 'شركة  تطبيقات النظم المستقبلية',
        msg_footer_3: 'حقوق النشر © 2021. جميع الحقوق محفوظة.',

        msg_lang: 'English',
        msg_terms: 'الشروط و الأحكام',
        msg_terms_1: ' منصة رابط متخصصة فى إدارة خدمات الفواتيرالإلكترونية و خدمات التكامل مع منظومة الفاتورة الإلكترونية بجمهورية مصر العربية، ومعالجة المعاملات وتأجير البرمجيات عبر الإنترنت وكذلك جميع الخدمات الاحترافية المتعلقة بذلك او الخدمات الأخرى ذات الصلة. ويشار الى جميع ما سبق بمصطلح "الخدمات". وفيما يلي البنود والشروط والأحكام الخاصة باستخدام هذه الخدمات، إلى جانب أي تعديلات عليها وأي قواعد أو سياسات تشغيلية قد يتم نشرها من وقت لآخر بواسطة "رابط".',
        msg_terms_2: 'أولًا: الخدمات والدعم',
        msg_terms_3: '١. يتم تقديم الخدمات وفقًا لهذه الشروط وأي سياسات تشغيل قد تنشئها الشركة ؛ والتي يشار إليها جميعًا بمصطلح "الاتفاقية". قد تقوم منصة رابط بإجراء تغييرات على هذه الاتفاقية وهذه الشروط، ويُعتبر الاستخدام المستمر لأي خدمات مُقدمة من الشركة بمثابة موافقة من العميل على أي من هذه التغييرات. بالإضافة إلى ذلك عند استخدام خدمات معينة فإن العميل والشركة يخضعان لأي إرشادات أو قواعد منشورة تنطبق على هذه الخدمات المعينة والتي يمكن نشرها من وقت لآخر.',
        msg_terms_4: '٢. الخدمات متوفرة فقط للشركات والافراد الذين يمكنهم توقيع وإبرام عقود ملزمة قانونًا بموجب القانون المعمول به. دون تحديد ما تقدم، لا تتوفر الخدمات للأفراد الذين تقل أعمارهم عن 18 عامًا. لذلك إذا لم تكن مؤهلاً، فيرجى عدم استخدام الخدمات.',
        msg_terms_5: '٣. يضمن رابط حصولك على منصة خاصة بك هذه المنصة تحتوى بيانات الممول والعملاء والسلع والخدمات، وللمنصة كافة ضمانات سرية المعلومات حيث أنه لا يمكن الدخول إليها إلا من خلال المستخدمين الخاصيين بمنصتك.',
        msg_terms_6: 'ثانيًا: القيود والمسؤوليات',
        msg_terms_7: '١. هذه اتفاقية للخدمات وبمقتضاها لم يتم منح العميل ترخيصًا لأي برنامج بموجب هذه الاتفاقية. وبناء عليها يتعهد العميل بأن لا يقوم بشكل مباشر أو غير مباشر بالتالي:',
        msg_terms_8: '– إجراء هندسة عكسية أو فك الشفرات او الأكواد الخاصة بالشركة.',
        msg_terms_9: '– محاولة اكتشاف رمز او أكواد المصدر أو رمز او أكواد الكائنات البرمجية أو البنية الأساسية للخدمات أو الأفكار المستخدمة أو الخوارزميات أو الخدمات الأساسية أو المضمنة في الخدمات أو أي برامج او برمجيات أو وثائق أو بيانات متعلقة بـ الخدمات. ويشار الى هذا كله بمصطلح "البرمجيات".',
        msg_terms_10: '– تعديل أو ترجمة أو إنشاء أعمال مشتقة بناءً على الخدمات أو أي برنامج او برمجيات أو نسخ (باستثناء الأغراض الأرشيفية) أو توزيع أو تعهد أو تخصيص أو نقل حقوق أو تحويلها إلى خدمات أو أي برنامج.',
        msg_terms_11: '– إزالة أي إشعارات أو علامات ملكية.',
        msg_terms_12: '٢. يقر العميل ويتعهد ويضمن أنه سوف يستخدم الخدمات فقط وفقًا لسياسات خصوصية الشركة المالكة لمنصة رابط، كما هو موضح أدناه أو مقدم بطريقة أخرى إلى العميل وجميع القوانين المعمول بها في جمهورية مصر العربية. يوافق العميل بموجبه على تعويض للشركة وتلافي الضرر المُلحق بها ضد أي أضرار أو خسائر أو مطلوبات أو تسويات أو نفقات. ',
        msg_terms_13: 'ثالثًا: فسخ التعاقد',
        msg_terms_14: '١. يجوز للعميل إنهاء هذه الاتفاقية في أي وقت عن طريق إرسال رسالة بريد إلكتروني إلى support@rabet-eg.com',
        msg_terms_15: '٢ .قد تقوم الشركة بإنهاء هذه الاتفاقية أو الخدمات في أي وقت في حالة انتهاك أي من السياسات مع أو بدون إشعار. ويقبل العميل ألا تتحمل الشركة أية مسؤولية تجاه العميل أو أي طرف ثالث بسبب هذا الإنهاء.',
        msg_terms_16: '٣. عند الإنهاء لأي سبب من الأسباب ، يجوز لـ "رابط" حذف أي بيانات مؤرشفة من قبل العميل في غضون 90 (تسعين) يومًا من تاريخ الإنهاء. جميع أقسام هذه الاتفاقية التي يجب أن تظل بطبيعتها سارية المفعول حتى بعد انهاء الاتفاقية ويشمل ذلك المسؤولية المحدودة او غير المحدودة.',
        msg_terms_17: 'رابعًا: الدفع وتجديد الخدمة',
        msg_terms_18: '١. شرط الخدمة:  تبدأ مدة اشتراك العميل في الخدمات عند قبول العميل لهذه الشروط وتنتهى وفق الاشتراطات المحددة باستمارة التسجيل.',
        msg_terms_19: '٢. الدفع: يوافق العميل على دفع جميع الرسوم المطبقة على الخدمات السارية في وقت التسجيل او الاستخدام الأول او التجديد مع مراعاة هذه الشروط كما يجوز تعديلها بواسطة الشركة المالكة لمنصة "رابط" من وقت لآخر.',
        msg_terms_20: '٣. الإلغاء والمبالغ المستردة: لا توفر منصة رابط ضمان استعادة الأموال لتسجيلات الحساب الجديد تحت أي ظرف من الظروف.',
        msg_terms_21: '٤ . القرصنة: "القرصنة" والأنشطة ذات الصلة محظورة مطلقاً تشمل "القرصنة"، على سبيل المثال لا الحصر الأنشطة التالية: بشكل غير قانوني أو بدون إذن، الوصول إلى أجهزة الكمبيوتر أو الحسابات أو الشبكات، اختراق أو محاولة اختراق تدابير الأمان، وغيرها من الأنشطة المصممة للمساعدة في القرصنة. ',
        msg_terms_22: '٥. وكلاء مجهولون "Anonymous Proxies": لا نسمح باستخدام البرامج النصية الخاصة بالوكلاء المجهولين على خوادمها. يمكن أن تكون مسيئة للغاية لموارد الخادم، مما يؤثر على جميع المستخدمين على هذا الخادم.',
        msg_terms_23: '٦. النسخ الاحتياطية: من أجل كفاءاتها وأغراضها التشغيلية، تقوم منصة رابط  من وقت لآخر بنسخ البيانات احتياطيًا على خوادمها، ولكنها ليست ملزمة أو ملزمة للعميل بالقيام بذلك بموجب هذه الشروط. ويقر العميل إنه بواجبه وحده ومسؤوليته عن النسخ الاحتياطي لملفات او بيانات العميل على منصة رابط . وتحت أي ظروف فإن منصة رابط لن تكون ملزمة لأي أحد بسبب أي أضرار من أي نوع او تحت أي نظرية قانونية تنتج عن فقد لملفات او بيانات العميل .',
        msg_terms_24: '٧.  القانون الحاكم: تخضع هذه الشروط لقوانين جمهورية مصر العربية دون الرجوع إلى مبادئ تنازع القوانين. يوافق العميل على أن جميع المنازعات -إن وجدت- التي تنطوي على الشركة تخضع بشكل حصري للولاية القضائية لجمهورية مصر العربية؛ وبالتالي، يجب رفع جميع الدعاوى المرفوعة ضد الشركة في المحاكم المختصة في جمهورية مصر العربية. ',
        msg_terms_25: '٤. حيث أن خدمات "رابط" تقوم على التكامل مع منظومة الفاتورة الإلكترونية المقدمة من خلال مركز البيانات الخاص بمصلحة الضرائب المصرية فإن منصة "رابط" لا تعد مسؤولة بأى شكل من الأشكال عن انقطاع الخدمة أو اضطرابها من قبل مصلحة الضرائب وبالتالى لن يكون هناك أى مجال للمطالبة بأية تعويضات مادية أو الحق فى استرجاع قيمة الإشتراك حال حدوث أى مشاكل تقنية من قبل مصلحة الضرائب.',
        msg_phone: '٠١٠١٣١٣٣٥٤٦',
        msg_fax: '(+٢٠) ٢٣٥٣٧١٢١٦',
        msg_user_pending: 'المستخدم في انتظار تأكيد المسؤول',
        msg_user_not_found: 'المستخدم غير موجود الرجاء المحاولة مرة اخرى',
        msg_company_not_found: 'اسم الشركة غير موجود الرجاء المحاولة مرة اخرى',
        msg_contact_success: 'تم الإرسال بنجاح ، شكرًا',
        msg_contact_error: 'عذرا، حاول مرة اخرى',
        msg_confirm_password_error: 'تأكيد كلمة المرور غير متشابهة',
        msg_agree_terms: ' أوافق على',
        msg_agree_terms_1: ' الخاصة بنا ',
        msg_wrong_mail: 'البريد الإلكتروني غير صحيح',
        msg_wrong_mail_or_password: 'البريد الإلكتروني أو كلمة المرور غير صحيحة',
        msg_verify_email_and_password: 'تحقق من كلمة المرور والبريد الالكتروني',
        msg_reset: 'إعادة ضبط',

        msg_profile_credentials: 'تسجيل بيانات الإشتراك',
        msg_client_id: 'Client ID',
        msg_client_secret: 'Client Secret',
        msg_profile_credentials_1: "ملاحظة: بمجرد حفظ بيانات الإشتراك الخاصة بك لا يمكنك تغييرها !!",
        msg_profile_credentials_2: 'إذا كنت بحاجة لتعديل بياناتك، قم بإرسال بريد إلكترونى ل support@rabet-eg.com',

        msg_profile_onboarding_1: 'يتم استخدام"agent" لتوقيع المستندات وإرسال ملفات المستندات التي تم إنشاؤها من تخطيط موارد المؤسسات (ERP).',
        msg_profile_onboarding_2: 'حساب الخدمة هو نوع خاص من الحسابات يستخدمه التطبيق (وكيل التوقيع) للتواصل مع نظامنا. بعد تنزيل حساب الخدمة ، انسخه ثم انتقل إلى مجلد الإعدادات في مجلد تثبيت agent والصقه.',
        msg_profile_onboarding_3: 'ملف الإعدادات الذي يستخدمه (وكيل التوقيع): بعد تنزيل ملف الإعدادات ، انسخه ثم انتقل إلى مجلد الإعدادات في مجلد تثبيت  ال Agent والصقه.',

        msg_subscription_free: 'جرب رابط (١٤ يومًا)',
        msg_free_trial_start_today: 'ابدا اليوم بتجربة رابط مجانا لمدة ١٤ يومًا',
        msg_free: "مجانا",
        msg_subscription: 'الإشتراك',

        msg_change: 'تغيير',
        msg_extend_package: 'طلب تجديد الباقة',
        msg_download_template: 'قم بتنزيل النموذج ثم املأه ',
        msg_import_excel: 'تحميل ملف Excel',
        msg_products_upload: 'المنتجات التى سوف يتم تحميلها: ',
        msg_addresses_upload: 'العناوين التى سوف يتم تحميلها: ',
        msg_upload: 'تحميل',
        msg_note_renew: 'ملحوظة: تضاف الفواتير المتبقية من الباقةالحالية إلى الباقة الجديدة',

        msg_instructions: 'الإرشادات',
        msg_instructions_title: 'إرشادات عامة للتسجيل في منظومة الفاتورة الإلكترونية',
        msg_instructions_intro: 'مقدمة',
        msg_instructions_paragraph1: 'تبنت وزارة المالية ومصلحة الضرائب المصرية خطوات عدة لتحقيق رؤية مصر 2030 وتحقيق التحول الرقمي في المنظومة المالية وتطوير المنظومة الضريبية، وإحدى تلك الخطوات هي تطبيق نظام الفاتورة الإلكترونية، وهي الخطوة الأولى الحقيقية للحكومة لدمج الاقتصاد غير الرسمي في القناة الشرعية للاقتصاد الرسمي، حيث سيتم إلزام مجتمع الأعمال بإصدار فاتورة إلكترونية في القانون الجديد الذي سيربط جميع الممولين على مستوى الجمهورية مع مصلحة الضرائب، ولكن ما هي الفاتورة الإلكترونية؟!',
        msg_instructions_paragraph2: 'الفاتورة الإلكترونية هي فاتورة أو مستند رسمي يثبت معاملات البيع، يتم إصدارها وتعديلها بصيغة إلكترونية وكذلك إرسالها للجهات المختصة، وعليه فإنه يمكن اعتبار الفاتورة الإلكترونية أنها نظام منخفض التكاليف لمعالجة المعاملات التي تستفيد من تكنولوجيا المعلومات لتحويل عملية إعداد الفواتير اليدوية والورقية إلى صيغة إلكترونية أكثر فاعلية في معالجة رسائل البيانات والمحافظة على السجلات.',
        msg_instructions_paragraph3: 'أهم ملامح منظومة الفاتورة الإلكترونية',
        msg_instructions_paragraph4: 'بينما أعلنت مصلحة الضرائب المصرية بوزارة المالية عن أهم ملامح المنظومة الجديدة التي بدأت في تطبيقها مطلع عام 2020، حددت بعض السمات للفاتورة الإلكترونية التي ستستخدمها الشركات المساهمة في المنظومة، وهي:',
        msg_instructions_paragraph5: '- رقم فريد للفاتورة الإلكترونية',
        msg_instructions_paragraph6: '- توحيد الشكل والمحتوى للفاتورة الإلكترونية',
        msg_instructions_paragraph7: '- كود موحد للسلع/المنتجات',
        msg_instructions_paragraph8: '- توقيع إلكتروني معتمد',
        msg_instructions_paragraph9: '- إخطار لحظي بالفاتورة الإلكترونية للطرفين؛ البائع والمشتري كليهما.',
        msg_instructions_paragraph10: '- حفظ الفاتورة مع إمكانية الاستعراض والطباعة.',
        msg_instructions_paragraph11: 'وكأي نظام فإن منظومة الفاتورة الإلكترونية تحتاج إلى اتباع عدة إجراءات من أجل التسجيل عليها وبدء العمل بها، وإليكم شرح مبسط لتلك الإجراءات:',
        msg_instructions_paragraph12: 'المستندات المطلوبة للتسجيل على المنظومة، وهي:',
        msg_instructions_paragraph13: '1. أصل وصورة بطاقة الرقم القومي أو جواز السفر بتاريخ ساري. ',
        msg_instructions_paragraph14: '2. أصل وصورة البطاقة الضريبية وشهادة تسجيل بالقيمة المضافة.',
        msg_instructions_paragraph15: '3. خطاب تفويض من الشركة للتسجيل في منظومة الفاتورة الإلكترونية محدد به:',
        msg_instructions_paragraph16: '- بيانات الشركة (عنوان الشركة، رقم التليفون الخاص بالشركة، الإيميل الخاص بالشركة، رقم تسجيل الشركة). ',
        msg_instructions_paragraph17: '- بيانات المفوض أو المدير المسؤول عن منظومة الفاتورة الإلكترونية بالشركة مُحَددًا به (الاسم باللغتين العربية والإنجليزية، الرقم القومي الموجود بالبطاقة الشخصية، رقم التليفون، الإيميل). ',
        msg_instructions_paragraph18: '*في حالة عدم حضور الممول وحضر عنه الوكيل فينبغي توفر كل ما سبق من المستندات مع أصل التوكيل بتاريخ ساري وصورة منه.',
        msg_instructions_paragraph19: 'تسليم مستندات التسجيل بمنظومة الفاتورة الإلكترونية',
        msg_instructions_paragraph20: 'تسليم المستندات التي تم إعدادها مسبقا،حيث يقوم مدير النظام بإرسال طلب التسجيل مرفقًا به مستندات التسجيل على العنوان eInvoice@eta.gov.eg   وذلك عبر الإيميل الرسمي للشركة، بحيث يقوم الموظف المسؤول بإخطار الممول أو المفوض بميعاد حضوره إلى مأمورية الضرائب التابع لها لمطابقة المستندات في حالة استيفائها والقيام بطلب ملف تعريف رقمي، أما في حالة نقصها يتم إرسال إخطار بالمستندات الناقصة عبر الإيميل، بعد ذلك يقوم الموظف المختص بالاطلاع على أصول المستندات وإجراء المطابقة بين أصول المستندات وما تم إرساله عبر البريد الإلكتروني وإعلامه بأنه سيتم تفعيل تسجيله بالمنظومة وإخطاره بذلك خلال 48 ساعة.',
        msg_instructions_paragraph21: 'إنشاء الملف الرقمي ومشاركة المعلومات',
        msg_instructions_paragraph22: 'يقوم موظف المصلحة المختص بتسجيل الممول وإنشاء الملف الرقمي على منظومة الفواتير الإلكترونية، بالإضافة إلى توفير دليل تعريفي شامل للممول بعد عملية تفعيل وتسجيل الملف الإلكتروني الخاص به، يتضمن شرح لمكونات المنظومة وندوات توعوية للرد بشكل مباشر على أي استفسارات. ',
        msg_instructions_paragraph23: 'استلام دعوة التسجيل بالبريد الإلكتروني',
        msg_instructions_paragraph24: ' يستقبل مدير النظام دعوة تسجيل الملف الرقمي من قبل الموظف المختص عبر البريد الإلكتروني ويكون مرفق به الرابط الذي سيتم من خلاله مواصلة إنشاء ملف تعريف المستخدم. ',
        msg_instructions_paragraph25: 'الحصول على شهادة التوقيع الإلكتروني',
        msg_instructions_paragraph26: 'وتلك الخطوة تتم بالتوازي مع الخطوات الأخرى، حيث يعد التوقيع الإلكتروني بمثابة التوقيع الرسميّ للمموّل، ويأخذ شكل رسالة مشفّرة، تتوافق مع اللوائح القانونية،  كما توفّر أعلى مستوى من التأكيد لهوية الموقّع.',
        msg_instructions_paragraph27: '*يمكن للممول الاشتراك في التوقيع الإلكتروني من خلال ',
        msg_instructions_paragraph27Link: 'شركة مصر للمقاصة والإيداع والقيد المركزي (M.C.D.R.) ',
        msg_instructions_paragraph27Link2: ' الشركة المصرية لخدمات التوقيع الإلكتروني وتأمين المعلومات (Egypt Trust)',
        msg_instructions_paragraph28: '*المستندات المطلوبة لاستخراج شهادة التوقيع الإلكتروني ',
        msg_instructions_paragraph29: '1. توقيع الطلب أو العقد من المدير المسؤول للمنشأة أو ممثلها القانوني حيث يكون له الحق علي التوقيع بموجب تفويض، ويكون طلب الحصول على الشهادة والتفويض موقعين من قبل المدير المسئول للمنشأة أو ممثلها القانوني ومصدقًا على توقيعاته بخاتم البنك المتعامل معه (توقيع مطابق).',
        msg_instructions_paragraph30: '2. تقديم الممثل القانوني أصول كافة المستندات والوثائق للاطلاع عليها وتتمثل في: ',
        msg_instructions_paragraph31: '- صورة من المستخرج الرسمي للسجل التجاري أو قرار الإنشاء/الإشهار.: ',
        msg_instructions_paragraph32: '- صورة من البطاقة الضريبية.',
        msg_instructions_paragraph33: '- صورة صحيفة الاستثمار أو الشركات أو عقد الشركة.',
        msg_instructions_paragraph34: '- صورة إثبات الشخصية للمدير المسؤول.',
        msg_instructions_paragraph35: 'إنشاء الملف الرقمي لمدير النظام',
        msg_instructions_paragraph36: 'يتم بعد ذلك إنشاء الملف الرقمي الخاص بمدير النظام من خلال ملء بعض المعلومات في شاشة إكمال الملف الشخصي ',
        msg_instructions_paragraph36_bold: 'الاسم الأول واسم العائلة بالعربية والإنجليزية، رقم الهاتف المحمول، الرقم القومي، عنوان الإيميل، اللغة المفضلة للتواصل',
        msg_instructions_paragraph36_2: 'بنفس الطريقة التي قام الموظف المختص بالمصلحة بإدراج تلك البيانات، ثم يتم إرسال كلمة مرور لمرة واحدة (OTP) للهاتف المحمول الخاص بمدير النظام، وأخيرا يقوم مدير النظام نيابةً عن الممول بكتابة كلمة المرور ثم التأكيد عليها مرةً أخرى، ثم النقر على زر الحفظ لاستكمال الخطوات. ',
        msg_instructions_paragraph37: 'انتهاء عملية التسجيل والانتقال لإعداد الإخطارات',
        msg_instructions_paragraph38: '- بعد اتباع كافة الخطوات السابقة تكون عملية التسجيل قد تمت.',
        msg_instructions_paragraph39: '- تقوم منظومة الفواتير الإلكتروني بتحويل مدير النظام تلقائيا على شاشة التسجيل لإعادة تسجيل الدخول.',
        msg_instructions_paragraph40: '- يقوم مدير النظام باستخدام نفس البريد الإلكتروني وكلمة المرور التي تم تعيينها في الخطوات السابقة والضغط على زر الدخول.',
        msg_instructions_paragraph41: '- ثم يقوم بالنقر على ملف الممول، والضغط علي زر إدارة الإخطارات.',
        msg_instructions_paragraph42: 'تظهر شاشة الإعدادات ويقوم مدير النظام من خلالها بإعداد واختيار التفضيلات الخاصة بالإخطارات (مثل وسيلة التواصل والفترات وأنواع الإخطارات) وحفظها.',
        msg_instructions_paragraph43: 'تسجيل نظام تخطيط موارد المؤسسة للممول "النظام المحاسبي"',
        msg_instructions_paragraph44: 'يقوم مدير النظام بتسجيل الدخول ثم النقر على ملف الممول، واختيار خانة الـ ERP والضغط علي زر التسجيل ليقوم نظام الفاتورة الإلكترونية بإظهار نموذج الإضافة لنظام الـ ERP التابع للممول ثم القيام بالآتي:',
        msg_instructions_paragraph45: '- ملئ البيانات مثل اسم النظام، ومدة الصلاحية المتاحة للنظام لتبادل المستندات من خلال التكامل.',
        msg_instructions_paragraph46: '- الضغط على زر الحفظ.',
        msg_instructions_paragraph47: '*يمكن للممول تسجيل أنظمة تخطيط موارد مؤسسية أخري إذا كانت متاحة له.',
        msg_instructions_paragraph56: '- بعد الحفظ، ستظهر شاشة الحصول على بيانات التسجيل والمصادقة للتكامل مع المنظومة، والتي تحتوي على: معرّف العميل ومفتاح سر ۱ ومفتاح سر۲، والتي ينبغي عليك نسخها والتأكد من الاحتفاظ بها قبل إغلاق هذه الشاشة.',
        msg_instructions_paragraph48: '- النقر فوق زر تم.',
        msg_instructions_paragraph49: '*تتيح منظومة الفاتورة الإلكترونية إضافة أكثر من مفوض بهذه الطريقة:',
        msg_instructions_paragraph50: '1. من نفس شاشة الممول يقوم مدير النظام باختيار خانة المستخدم ويقوم بالضغط على دعوة مستخدم. ',
        msg_instructions_paragraph51: '2. تظهر شاشة الدعوة ويقوم مدير النظام بإدخال بيانات الملف الشخصي للمفوض (الاسم الأول واسم العائلة باللغتين "العربية والإنجليزية – رقم الهاتف – الرقم القومي – الإيميل – فترة نشاط المستخدم وتحديد نوعه: مدير أو مندوب). ',
        msg_instructions_paragraph52: '3.الضغط على زر الحفظ.',
        msg_instructions_paragraph53: 'ثم يقوم النظام تلقائيا بإرسال دعوة للمستخدم الإضافي لاستكمال الخطوات وإنشاء الملف الرقمي كما تم توضيحه مسبقا.',
        msg_instructions_paragraph54: 'تكويد السلع والخدمات ',
        msg_instructions_paragraph55: 'تقوم مصلحة الضرائب المصرية في تنفيذ منظومة الفاتورة الإلكترونية على معايير عالمية لتكويد السلع والخدمات وتصنيفاتها، لضمان تبادل المستندات بطريقة معيارية موحدة ومنظمة تعمل على تقليل الخطأ وتوحيد تعريف السلع والخدمات بين الأطراف التجارية (البائع والمشتري)، ويوجد طريقتين للتكويد هما: ',
        msg_instructions_paragraph57: 'الأولى :  إذا  كانت اكواد سلع وخدمات شركتك وفقا لنظام التكويد العالمي GS1 ستقوم بتسجيل هذه الأكواد على منصه الفاتوره الالكترونيه للتأكد من تفعيل تلك الأكواد على قواعد بيانات منظومة الفاتورة الإلكترونية. ',
        msg_instructions_paragraph58: `الثانية :  إذا كانت اكواد سلع وخدمات شركتك اكواد داخلية  فلازم تتبع الخطوات التالية: `,
        msg_instructions_paragraph58_1: '1 – ربطها بنظام التصنيف السلعي GPC',
        msg_instructions_paragraph58_2: '2- تسجيل اكواد ال EGS على منصة الفاتورة الالكترونية والمكونة من الكود الداخلي بنظام GPC – رقم التسجيل – EG',
        msg_instructions_paragraph58_3: '3- والمصلحة هتراجع الأكواد دي علشان توافق عليها .',
        msg_instructions_paragraph58_4: '4- بإتمام موافقة المصلحة يتم استخدام اكواد ال EGS في إصدار الفاتورة الإلكترونية.',
        msg_instructions_paragraph58_5: `5-  و لو عندك سلعة أو خدمة ملهاش GPC يمكنك التواصل مع شركه GS1 لمساعدتك فى تحديد الكود الخاص بها مجانا , عن طريق إرسالك لإيميل  على الإيميل التالى
        einvoice@gs1eg.org`
        ,
        msg_instructions_paragraph58_6: 'وللمزيد قم بزيارة ',
        msg_instructions_paragraph58_7: ' المقدم من مصلحة الضرائب المصرية ',
        msg_instructions_paragraph59: 'ويمكنك البحث عن كود أي سلعة أو خدمة من خلال هذا الرابط ',
        msg_free_trial_start_today_note: 'تتم التجربة على الخادم التجريبي لمصلحة الضرائب',

        msg_type_name: 'اسم النوع',
        msg_type_id: 'رقم النوع',


        "CLIENT-001": "اسم المشترك مستخدم",
        "CLIENT-002": "حدث خطأ أثناء انشاء المشترك",
        "CLIENT-003": "حدث خطأ أثناء انشاء الحساب  ",
        "CLIENT-004": "قيمة غير صحيحة يجب ان تكون, ['unit', 'activity', 'country', 'tax', 'taxSub', ''nonTaxable']",
        "CLIENT-005": "لم يتم العثور على اسم المشترك",
        "CLIENT-006": "لم يتم العثور على المشترك",
        "CLIENT-007": "لم يتم العثور على طلب الاشتراك",
        "CLIENT-008": "لم يتم العثور على الطلب",
        "CLIENT-009": "خطأ في المستخدم, قم بإعادة تسجيل الدخول.",
        "CLIENT-011": "ملف فاتورة خاطئ",
        "CLIENT-012": "لم يتم العثور على العنوان, يرجى المحاولة لاحقا",
        "CLIENT-013": "خطأ في المدخلات",
        "CLIENT-014": "لم يتم العثور على اسم المشترك",
        "CLIENT-015": "لم يتم العثور على الفاتورة",
        "CLIENT-016": "لقد تخطيت عدد إرسالات الفواتير المتاحة",
        "CLIENT-017": "لقد انتهت فترة الاشتراك",
        "CLIENT-018": "الكود الداخلي موجود من قبل",
        "CLIENT-019": "يوجد طلب اشتراك مسبق",
        "CLIENT-020": "لم يتم العثور على الاشتراك",
        "CLIENT-021": "لم يتم العثور على المنتج",
        "CLIENT-022": "لم يتم العثور على المنتج",
        "CLIENT-023": "المنتج موجود بنفس الكود الداخلي",
        "CLIENT-024": "حدث خطأ اثناء تحميل بيناتا المستخدمين",
        "CLIENT-025": "حدث خطأ اثناء تحميل بيناتا المستخدم",
        "CLIENT-026": "خطأ في بيانات المستخدم",
        "CLIENT-027": "لا يمكن حذف المستخدم",
        "CLIENT-028": "خطأ أثناء حذف المستخدم",
        "CLIENT-029": "لم يتم العثور على المسودة",
        "CLIENT-030": "لم يتم العثور على ملف المسودة",
        "CLIENT-031": "غير مصرح",
        "CLIENT-032": "خطأ البريد الالكتروني موجود",
        "CLIENT-900": "BadRequest",
        "CLIENT-901": "BadArgument",
        "CLIENT-902": "NotFound",
        "CLIENT-903": "لا يمكن الوصول لمصلحة الضرائب, يرجى المحاولة لاحقا",
        "CLIENT-904": "لم يتم العثور على بيانات اشتراك مصلحة الضرائب",
        "CLIENT-905": "BadStructure",
        "CLIENT-906": "IncorrectSubmitter",
        "CLIENT-907": "MaximumSizeExceeded",
        "CLIENT-908": "DuplicateSubmission",
        "CLIENT-909": "OperationPeriodOver",
        "CLIENT-910": "IncorrectState",
        "CLIENT-911": "ActiveReferencingDocuments",
        "CLIENT-912": "ممنوع الوصول",
        "CLIENT-913": "NotReady",
        "CLIENT-914": "غير موجود",
        "CLIENT-915": "OperationExceedsLimit",
        "CLIENT-916": "ValidationError",
        "SERVER-001": "خطأ اثناء انشاء المشترك",
        "SERVER-002": "خطأ اثناء الارسال",
        "SERVER-003": "ESP didn't enforce firebase and it is required",
        "SERVER-004": "لم يتم العثور على البرنامج, يرجى التواصل مع الدعم",
        "SERVER-005": "لم يتم العثور على ملف حساب الخدمة, يرجى التواصل مع الدعم",
        "SERVER-006": "لم يتم العثور على ملف الاغددادات, يرجى التواصل مع الدعم",
        "SERVER-007": "لم يتم العثور على بيانات الاشتراك",
        "SERVER-008": "لم يتم العثور على طلب الاشتراك",
        "SERVER-009": "خطأ أثناء تحميل بيانات المستخمين",
        "SERVER-010": "خطأ أثناء تحميل بيانات المستخم",
        "SERVER-011": "خطأ أثناء إنشاء مستخدم جديد",
        "SERVER-012": "خطأ أثناء تعديل بيانات المستخدم",
        "SERVER-013": "خطأ أثناء حذف المستخدم",
        "SERVER-900": "غير مصرح بالوصول",
        "SERVER-901": "ممنوع الوصول",
        "SERVER-902": "غير موجود",
        "SERVER-903": "TooManyRequests",
        "SERVER-904": "خطأ بالخادم",
        "SERVER-905": "NotImplemented",
        "SERVER-906": "الخادم غير متاح",
        "SERVER-907": "مشكلة في الوصول",
        "SERVER-908": "TimeoutError",
        "SERVER-999": "حدث خطأ غير معروف",

        msg_add_address: 'بيانات الفرع',
        msg_add_receiver: 'بيانات العميل',
        msg_company_full_name_help_text: 'وفقا لبطاقتك الضريبية',
        msg_item_code_good: "كود السلعة/الخدمة",
        msg_upload_invoices_intro: 'يمكن إستخدام نموذج فواتير  Excel لإنشاء فواتيرك الإلكترونية بصيغة Excel ورفعها وإرسالها لمصحلة الضرائب',
        msg_full_invoice: 'فاتورة كاملة',
        msg_simplified_invoice: 'فاتورة مبسطة',
        msg_remember_me: 'تذكرنى',

        msg_faq_section_intero_1: 'رابط هي منصة تعمل علي تقديم خدمات الربط والتوقيع الإلكتروني للفواتير مع منظومة الفاتورة الإلكترونية بمصلحة الضرائب المصرية.',
        msg_faq_section_intero_2: 'نقدم بعض الاسئلة الشائعة المتكررة بين عملاءنا لمساعدتك فى استخدام منصة رابط:',

        msg_faq_section_head_1: 'أسئلة شائعة خاصة بالاشتراك',
        msg_faq_section_head_1_ques_1: '1. ما هي أنواع الاشتراكات التي يتيحها رابط؟',
        msg_faq_section_head_1_ques_2: '2. كيف يتم تفعيل حسابك على رابط؟',
        msg_faq_section_head_1_ques_3: '3. هل متاح التسجيل لأكثر من مرة على الباقة التجريبية Free trial على رابط "50 فاتورة / إشعار لمدة 14 يوم مجانا"؟',
        msg_faq_section_head_1_ques_4: '4. ما الذي يحدث قرب انتهاء مدة التجربة المجانية "Free trial"؟',
        msg_faq_section_head_1_ques_5: '5. ماذا يحدث عند إقتراب نهاية مدة الباقة أو عدد الفواتير المتاحة فيها ؟',
        msg_faq_section_head_1_ques_6: '6. فى حال كنت على الباقة التجريبية وأردت الإشتراك فى باقة اخرى، هل يجب إعادة خطوات الإعدادات التى قد قمت بها ؟',
        msg_faq_section_head_1_ques_7: '7. ما الذي يمكنك فعله في حال نسيت كلمة المرور الخاصة بك؟',

        msg_faq_section_head_1_ans_1: 'عدد الفواتير والإشعارات',
        msg_faq_section_head_1_ans_1_1: '50 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_2: '500 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_3: '1,000 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_4: '3,000 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_5: '10,000 فاتورة/ إشعار',
        msg_faq_section_head_1_ans_1_6: 'تجربة (مجانا)',
        msg_faq_section_head_1_ans_1_7: '2,500 ج. م',
        msg_faq_section_head_1_ans_1_8: '4,500 ج. م',
        msg_faq_section_head_1_ans_1_9: '12,000 ج. م',
        msg_faq_section_head_1_ans_1_10: '30,000 ج. م',
        msg_faq_section_head_1_ans_1_11: '14 يوم',
        msg_faq_section_head_1_ans_1_12: 'سنويا',

        msg_faq_section_head_1_ans_2: 'بعد القيام بمليء بيانات الاشتراك الخاصة بك والضغط على إنشاء حساب:',
        msg_faq_section_head_1_ans_2_1: '- فى حالة الباقة التجريبية: يتم إرسال إيميل يحمل كل تفاصيل اشتراكك لتأكيده ثم إرسال إيميل لإخطارك بموعد تفعيل حسابك وإرسال الملفات الخاصة باعدادات حسابك على رابط ، ثم يصلك خلال ساعتين إيميل التفعيل لتبدأ بالعمل على رابط.',
        msg_faq_section_head_1_ans_2_2: '- فى حالة الباقات الاخرى: يتم إرسال إيميل يحمل كل تفاصيل اشتراكك لتأكيده ثم إرسال إيميل لإخطارك بالمطالبة الخاصة بك وتفاصيل البنك ،وعند سداد قيمة اشتراكك يصل إيميل لتأكيد سدادك ثم يصلك خلال ساعتين إيميل التفعيل لتبدأ بالعمل على رابط.',
        msg_faq_section_head_1_ans_2_3: 'ملاحظة: علي العميل أن يتوجه بالمطالبة إلى البنك الأهلي المتحد "Ahli united bank" وذلك لسداد المبلغ المحدد للباقة التي تم اختيارها في حساب رابط : 0026 164362 003',

        msg_faq_section_head_1_ans_3: 'لا، لا يمكن حيث يسمح لكل عميل التسجيل مرة واحدة فقط علي الباقة التجريبية علي رابط، وفي حالة الرغبة في استمرار الحصول علي الخدمة يتم الإشتراك في أي باقة من الباقات التي يتيحها رابط.',
        msg_faq_section_head_1_ans_4: 'سوف يتم إخطارك بإيميل بقرب انتهاء باقة وايضا من خلال لوحة التحكم على منصة رابط لذا قبل انتهاء مدة الـ 14 يوم لابد من الاشتراك في إحدى الباقات التي يتيحها رابط من خلال البروفايل واختيار الاشتراك، ويتم سداد قيمة الاشتراك في حساب رابط رقم (0026 164362 003) في البنك الأهلي المتحد "Ahli united bank"، وذلك لضمان استمرار الخدمة.',

        msg_faq_section_head_2: 'أسئلة شائعة خاصة بالإعدادات "Onboarding"',
        msg_faq_section_head_2_ques_1: '1. ماهي الخطوات التي يجب اتباعها قبل البدء في تفعيل خدمات رابط مع مصلحة الضرائب؟',
        msg_faq_section_head_2_ques_2: '2. ما هي الخطوات التي يجب اتباعها لتفعيل خدمات رابط مع مصلحة الضرائب؟',
        msg_faq_section_head_2_ques_3: '3. كيف يعمل الـ agent الخاص برابط؟',
        msg_faq_section_head_2_ques_4: '4. كيف يمكنك الوصول للجهاز المتصل الـ token و الـ agent عن بعد إذا كنت بحاجة لذلك (إذا كنت تقوم برفع فواتيرك بصيغة json)؟',
        msg_faq_section_head_2_ques_5: '5. ماذا يحدث في حال تغيير الـ client secret والـ client id من جهة مصلحة الضرائب؟',

        msg_faq_section_head_2_ans_1: '',

        msg_faq_section_head_3: 'أسئلة شائعة خاصة بالتشغيل',
        msg_faq_section_head_3_1: 'الجزء الأول: البروفايل',
        msg_faq_section_head_3_2: 'الجزء الثانى: لوحة التحكم',
        msg_faq_section_head_3_ques_1: '1. كيفية إضافة الفروع الخاصة بك؟',
        msg_faq_section_head_3_ques_2: '2. كيفية إضافة بيانات العملاء الذين سيتم التعامل معهم؟',
        msg_faq_section_head_3_ques_3: '3. كيفية إضافة السلع والخدمات الخاصة بك؟',
        msg_faq_section_head_3_ques_4: '4. هل يتيح لك رابط تغيير كلمة المرور الخاصة بك؟',
        msg_faq_section_head_3_ques_5: '5. كيفية إنشاء وإرسال الفاتورة الإلكترونية لمصلحة الضرائب من خلال رابط؟',
        msg_faq_section_head_3_ques_6: '6. كيفية معرفة سبب رفض الفاتورة في حال الرفض "Invalid"؟',
        msg_faq_section_head_3_ques_7: '7. كيفية متابعة الإرسالات الخاصة بك؟',
        msg_faq_section_head_3_ques_8: '8. كيفية إنشاء إشعار خصم "دائن" وإشعار إضافة "مدين"؟',

        msg_faq_section_head_3_ans_1: '',

        msg_upload_invoices_intro_1: '( يرجى التأكد من إدخال كافة البيانات الخاصة بنموذج الفواتير الإلكترونية (Excel) بصورة كاملة وصحيحة ) ',
        msg_simplified: 'مبسطة',
        msg_full: 'كاملة',
        msg_user_manual: 'دليل الإستخدام',
        msg_contact_subtitle: '(اتصل بنا في اي وقت علي مدار الساعة) ',
    }
}
