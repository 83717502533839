import React from 'react';
import { browserVersion, browserName } from 'react-device-detect';
import BrowserSupportPage from '../../pages/BrowserSupport';

export default function BrowserSupport({ children, supportedBrowsers = {} }) {
    React.useEffect(() => {
        document.querySelector(".preloader").classList.add("loader-hide");
    }, [])

    const isBrowserSupported = !(browserName in supportedBrowsers) || (supportedBrowsers[browserName] && browserVersion > supportedBrowsers[browserName])
    return (
        !browserName || !browserVersion
            ? ""
            : isBrowserSupported
                ? children
                : <BrowserSupportPage />
    )
}