import React from "react";
import "./App.css";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import RTL from "./components/rtl";
import ScrollToTop from "./components/scrollToTop";
import { UserContext } from "./providers/UserProvider";
import { AuthRoute, ProtectedRoute } from "./components/CustomRoutes";
import { USER_ROLES } from './providers/UserProvider'

const StatisticPage = React.lazy(() => import("./pages/Statistic"))
const DocumentsPage = React.lazy(() => import("./pages/Documents"))
const DocumentPage = React.lazy(() => import("./pages/Document"))
const PackagesPage = React.lazy(() => import("./pages/Packages"))
const TypesPage = React.lazy(() => import("./pages/Types"))
const TypePage = React.lazy(() => import("./pages/Type"))
const TypeVersionPage = React.lazy(() => import("./pages/TypeVersion"))
const UnitsPage = React.lazy(() => import("./pages/Units"))
const FAQPage = React.lazy(() => import("./pages/FAQ"))
const ProfileCredentialsPage = React.lazy(() => import("./pages/ProfileCredentials"))
const ProfileSettingsPage = React.lazy(() => import("./pages/ProfileSettings"))
const ProfileNotificationPage = React.lazy(() => import("./pages/ProfileNotification"))
const SubmissionPage = React.lazy(() => import("./pages/Submission"))
const NotificationsPage = React.lazy(() => import("./pages/Notifications"))
const SubmitDocumentPage = React.lazy(() => import("./pages/SubmitDocument"))
const SubmitDocumentsBulkPage = React.lazy(() => import("./pages/SubmitDocumentsBulk"))
const CodesPage = React.lazy(() => import("./pages/Codes"))
const CodePage = React.lazy(() => import("./pages/Code"))
const ProfileAddressesPage = React.lazy(() => import("./pages/ProfileAddresses"))
const ProfileSubmitAddressesPage = React.lazy(() => import("./pages/ProfileSubmitAddresses"))
const ProfileProductsPage = React.lazy(() => import("./pages/ProfileProducts"))
const ProfileSubmitProductsPage = React.lazy(() => import("./pages/ProfileSubmitProducts"))
const ResetPasswordPage = React.lazy(() => import("./pages/ResetPassword"))
const ChangePasswordPage = React.lazy(() => import("./pages/ChangePassword"))
const OnboardingPage = React.lazy(() => import("./pages/ProfileOnboarding"))
const SubscriptionPage = React.lazy(() => import("./pages/ProfileSubscription"))
const RegisterThanksPage = React.lazy(() => import("./pages/RegisterThanks"))
const TermsPage = React.lazy(() => import("./pages/Terms"))
const InstructionsPage = React.lazy(() => import("./pages/Instructions"))
const UserManualPage = React.lazy(() => import("./pages/UserManual"))
const HomePage = React.lazy(() => import("./pages/Home"))
const RegisterPage = React.lazy(() => import("./pages/Register"))
const DraftsPage = React.lazy(() => import("./pages/Drafts"))
const DraftPage = React.lazy(() => import("./pages/Drafts/Draft.views"))
const UsersPage = React.lazy(() => import("./pages/Users"))
const CreateUserPage = React.lazy(() => import("./pages/Users/Add.views"))
const EditUserPage = React.lazy(() => import("./pages/Users/Edit.views"))
const LoginPage = React.lazy(() => import("./pages/Login"))

const DailyStatusPage = React.lazy(() => import("./pages/DailyStatus"))
const ScheduleUploadsPage = React.lazy(() => import("./pages/ScheduleUploads"))
const ScheduleUploadPage = React.lazy(() => import("./pages/ScheduleUpload"))

const NotFoundPage = React.lazy(() => import("./pages/NotFound"))

const loader = document.querySelector(".preloader");
const addClass = () => loader.classList.add("loader-hide");
const language_direction = {
  en: "ltr",
  ar: "rtl",
};
const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: "#025492",
        light: "rgba(234,243,250,1)",
      },
      secondary: {
        main: "#FFA200",
        contrastText: "#fff",
      },
    },
    direction: language_direction[localStorage.getItem("lang") || 'ar'],
  })
);
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function App() {
  const query = useQuery()
  const history = useHistory()
  const location = useLocation();
  const queryLang = query.get('lang')

  React.useEffect(() => {
    // update query params
    const lang = localStorage.getItem("lang") || 'ar'
    document.body.dir = language_direction[lang]
    query.set('lang', lang)
    if (lang !== queryLang)
      history.replace({ ...location, search: query.toString() });
    else {
      //load chat
      if (window.HubSpotConversations) {
        const status = window.HubSpotConversations.widget.status();
        if (status.loaded)
          window.HubSpotConversations.widget.refresh();
        else
          window.HubSpotConversations.widget.load();
      }
    }
    addClass();
    return () => {
      document.body.dir = null;
    }
  }, [query, history, location.pathname, queryLang])


  return (
    <React.Suspense fallback={
      <div className="preloader">
        <img src="/logo192.png" width="150" alt="Rabet Logo" />
      </div>
    }>
      <UserContext.Consumer>
        {({ user, isLoading }) => (
          <RTL>
            <ThemeProvider theme={theme}>
              <ScrollToTop />
              {!(isLoading) && (
                <Switch>
                  <AuthRoute exact path="/home" render={(props) => <HomePage {...props} />} />
                  <AuthRoute exact path="/register/thank-you" render={(props) => <RegisterThanksPage {...props} />} />
                  <AuthRoute exact path="/login" render={(props) => <LoginPage {...props} />} />
                  <AuthRoute exact path="/register" render={(props) => <RegisterPage {...props} />} />
                  <AuthRoute exact path="/reset-password" render={(props) => <ResetPasswordPage {...props} />} />

                  <ProtectedRoute exact path="/" render={(props) => <StatisticPage {...props} />} />
                  <ProtectedRoute exact path="/usermanual" render={(props) => <UserManualPage {...props} />} />
                  <ProtectedRoute exact path="/faq" render={(props) => <FAQPage {...props} />} />
                  <ProtectedRoute exact path="/profile/change-password" render={(props) => <ChangePasswordPage {...props} />} />
                  <ProtectedRoute exact path="/packages" render={(props) => <PackagesPage {...props} />} />
                  <ProtectedRoute exact path="/types/:typeId/versions/:versionId" render={(props) => <TypeVersionPage {...props} />} />
                  <ProtectedRoute exact path="/types/:id" render={(props) => <TypePage {...props} />} />
                  <ProtectedRoute exact path="/types" render={(props) => <TypesPage {...props} />} />
                  <ProtectedRoute exact path="/units" render={(props) => <UnitsPage {...props} />} />
                  <ProtectedRoute exact path="/notifications" render={(props) => <NotificationsPage {...props} />} />
                  <ProtectedRoute exact path="/codes/:id" render={(props) => <CodePage {...props} />} />
                  <ProtectedRoute exact path="/codes" render={(props) => <CodesPage {...props} />} />

                  <ProtectedRoute exact path="/drafts"
                    render={(props) => <DraftsPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/drafts/:entity_id"
                    render={(props) => <DraftPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />

                  <ProtectedRoute exact path="/profile/users"
                    render={(props) => <UsersPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN]}
                  />
                  <ProtectedRoute exact path="/profile/users/create"
                    render={(props) => <CreateUserPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN]}
                  />
                  <ProtectedRoute exact path="/profile/users/:id"
                    render={(props) => <EditUserPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN]}
                  />

                  <ProtectedRoute exact path="/profile/service-account"
                    render={(props) => <OnboardingPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
                  />

                  <ProtectedRoute exact path="/profile/subscription"
                    render={(props) => <SubscriptionPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
                  />

                  <ProtectedRoute exact path="/documents/upload"
                    render={(props) => <SubmitDocumentsBulkPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/documents/add"
                    render={(props) => <SubmitDocumentPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/documents/edit/:documentUUID"
                    render={(props) => <SubmitDocumentPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/documents/resubmit/:uuid/:source"
                    render={(props) => <SubmitDocumentPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/documents/:documentUUID" render={(props) => <DocumentPage {...props} />} />
                  <ProtectedRoute exact path="/documents/:documentUUID/:source" render={(props) => <DocumentPage {...props} />} />
                  <ProtectedRoute exact path="/documents" render={(props) => <DocumentsPage {...props} />} />

                  <ProtectedRoute exact path="/daily-status"
                    render={(props) => <DailyStatusPage {...props} />}
                  />
                  <ProtectedRoute exact path="/scheduled-uploads"
                    render={(props) => <ScheduleUploadsPage {...props} />}
                  />
                  <ProtectedRoute exact path="/scheduled-uploads/upload"
                    render={(props) => <SubmitDocumentsBulkPage {...props} type='scheduled' />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/scheduled-uploads/:id"
                    render={(props) => <ScheduleUploadPage {...props} />}
                  />

                  <ProtectedRoute exact path="/profile/addresses/add/:type"
                    render={(props) => <ProfileSubmitAddressesPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/profile/addresses/edit/:type/:id"
                    render={(props) => <ProfileSubmitAddressesPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/profile/addresses/issuer" render={(props) => <ProfileAddressesPage type="issuer" {...props} />} />
                  <ProtectedRoute exact path="/profile/addresses/receiver" render={(props) => <ProfileAddressesPage type="receiver" {...props} />} />

                  <ProtectedRoute exact path="/profile/notifications"
                    render={(props) => <ProfileNotificationPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
                  />

                  <ProtectedRoute exact path="/profile/credentials"
                    render={(props) => <ProfileCredentialsPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
                  />

                  <ProtectedRoute exact path="/profile/products/add"
                    render={(props) => <ProfileSubmitProductsPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />
                  <ProtectedRoute exact path="/profile/products" render={(props) => <ProfileProductsPage {...props} />} />
                  <ProtectedRoute exact path="/profile/products/:id/edit"
                    render={(props) => <ProfileSubmitProductsPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER]}
                  />

                  <ProtectedRoute exact path="/profile/settings"
                    render={(props) => <ProfileSettingsPage {...props} />}
                    roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
                  />

                  <ProtectedRoute exact path="/submissions/:id" render={(props) => <SubmissionPage {...props} />} />
                  <ProtectedRoute exact path="/submissions/:id/:source" render={(props) => <SubmissionPage {...props} />} />

                  <Route exact path="/terms" render={(props) => <TermsPage {...props} />} />
                  <Route exact path="/instructions" render={(props) => <InstructionsPage {...props} />} />
                  <Route component={NotFoundPage} />
                </Switch>
              )}
            </ThemeProvider>
          </RTL>
        )}
      </UserContext.Consumer>
    </React.Suspense>
  );
}

