import axios from 'axios';
import { firebase } from './components/firebase';
import { language } from './components/trans'
import history from "./history.js";

export const baseURL = process.env.REACT_APP_BASE_URL;
// export const baseURL = "http://localhost:8080";
export const apiURL = baseURL + "/api/v1";


const instances = []


const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
}


export const profileAPI = axios.create({
    baseURL: `${apiURL}/profile`,
    headers: headers
});
instances.push(profileAPI)

export const documentAPI = axios.create({
    baseURL: `${apiURL}/documents`,
    headers: headers
});
instances.push(documentAPI)

export const notificationAPI = axios.create({
    baseURL: `${apiURL}/notifications`,
    headers: headers
});
instances.push(notificationAPI)

export const scheduledUploadsAPI = axios.create({
    baseURL: `${apiURL}/scheduled-uploads`,
    headers: headers
});
instances.push(scheduledUploadsAPI)

export const manualStartScheduledSubmissionsAPI = axios.create({
    baseURL: `${apiURL}/manual-start-scheduled-submissions`,
    headers: headers
});
instances.push(manualStartScheduledSubmissionsAPI)

export const dailyStatusAPI = axios.create({
    baseURL: `${apiURL}/daily-status`,
    headers: headers
});
instances.push(dailyStatusAPI)

export const codeAPI = axios.create({
    baseURL: `${apiURL}/codes`,
    headers: headers
});
instances.push(codeAPI)

export const statisticAPI = axios.create({
    baseURL: `${apiURL}/statistics`,
    headers: headers
});
instances.push(statisticAPI)

export const unitAPI = axios.create({
    baseURL: `${apiURL}/units`,
    headers: headers
});
instances.push(unitAPI)

export const pubsubAPI = axios.create({
    baseURL: `${apiURL}/pubsub`,
    headers: headers
});
instances.push(pubsubAPI)

export const tenantAPI = axios.create({
    baseURL: `${apiURL}/tenants`,
    headers: headers
});
instances.push(tenantAPI)

export const packageAPI = axios.create({
    baseURL: `${apiURL}/packages`,
    headers: headers
});
instances.push(packageAPI)

export const registerAPI = axios.create({
    baseURL: `${apiURL}/register`,
    headers: headers
});
instances.push(registerAPI)

export const crashReportAPI = axios.create({
    baseURL: `${apiURL}/crash-report`,
    headers: headers
});
instances.push(crashReportAPI)

export const contactUsAPI = axios.create({
    baseURL: `${apiURL}/contact-us`,
    headers: headers
});
instances.push(contactUsAPI)

export const submissionAPI = axios.create({
    baseURL: `${apiURL}/submissions`,
    headers: headers
});
instances.push(submissionAPI)

export const subscriptionAPI = axios.create({
    baseURL: `${apiURL}/subscriptions`,
    headers: headers
});
instances.push(subscriptionAPI)

export const draftAPI = axios.create({
    baseURL: `${apiURL}/drafts`,
    headers: headers
});
instances.push(draftAPI)

export const userAPI = axios.create({
    baseURL: `${apiURL}/users`,
    headers: headers
});
instances.push(userAPI)

instances.forEach(instance => {
    instance.interceptors.request.use(async function (config) {
        config.params = config.params ?? {};
        config.params.key = process.env.REACT_APP_API_KEY;
        config.params.language = language; // user selected language ['en', 'ar']
        const user = firebase.auth().currentUser
        if (user) {
            let idToken = await user.getIdToken();
            config.headers['Authorization'] = `Bearer ${idToken}`;
        }
        return config;
    }, function (err) {
        return Promise.reject(err);
    });
    instance.interceptors.response.use(function (response) {
        return response
    }, function (err) {
        if (err.response) {
            if (!err.response.config.baseURL.includes('notifications')) {
                switch (err.response.data.rabet_code) {
                    case 'CLIENT-904':
                        history.push('/profile/credentials')
                        break;
                    case 'SERVER-007':
                        history.push('/profile/subscription')
                        break
                    case 'CLIENT-016':
                        history.push('/profile/subscription')
                        break;
                    case 'CLIENT-017':
                        history.push('/profile/subscription')
                        break;
                    default:
                }
            }
        }
        return Promise.reject(err);
    });
})
