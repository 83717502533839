import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Toolbar,
    IconButton,
    Button,
    Typography,
    Link,
    MenuItem,
    Menu,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    Grid,
    Paper,
    withWidth,
    isWidthUp,
    Divider,
    Collapse
} from "@material-ui/core";
import {
    Menu as MenuIcon,
    AccountCircle,
    Language as LanguageIcon,
    ExpandLess,
    ExpandMore,
    Layers as LayersIcon,
    NoteAdd as NoteAddIcon,
    ViewWeek as ViewWeekIcon,
    Timeline as TimelineIcon,
    LiveHelp as LiveHelpIcon
} from '@material-ui/icons';

import {
    Business as BusinessIcon,
    ExitToApp as ExitToAppIcon,
    Person as PersonIcon,
    Code as CodeIcon,
    Lock as LockIcon,
    ArrowBackIos as ArrowBackIosIcon,
    Description as DescriptionIcon,
    Notifications as NotificationsIcon,
    Dashboard as DashboardIcon,
    Autorenew as AutorenewIcon,
    Group as GroupIcon,
    LibraryBooks as LibraryBooksIcon,
    Bookmarks as BookmarksIcon,
    People as PeopleIcon,
    BarChart as BarChartIcon,
    Schedule as ScheduleIcon,
} from '@material-ui/icons';
import {
    Link as RouterLink, useHistory,
} from 'react-router-dom';
import strings, { language } from '../components/trans'
import logo from '../assets/images/Rabet.png';
import logoEn from '../assets/images/Rabet-EN.png';
import { firebase } from '../components/firebase';
import { DrawerContext } from "../providers/DrawerProvider";
import DashboardFooter from "../components/dashboardFooter";
import { Helmet } from 'react-helmet';
import { UserContext, USER_ROLES } from '../providers/UserProvider';



const drawerItemsProfile = [
    {
        title: strings.msg_eta_profile_category,
        type: 'category',
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
    },
    {
        title: strings.msg_credentials,
        path: "/profile/credentials",
        icon: <PersonIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
    },
    {
        title: strings.msg_onboarding,
        path: "/profile/service-account",
        icon: <DescriptionIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
    },
    {
        title: strings.msg_notifications_settings,
        path: "/profile/notifications",
        icon: <NotificationsIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
    },
    {
        title: strings.msg_rabet_profile_category,
        type: 'category'
    },
    {
        title: strings.msg_users,
        path: "/profile/users",
        icon: <PeopleIcon />,
        roles: [USER_ROLES.SUPER_ADMIN],
    },
    {
        title: strings.msg_profile_settings,
        path: "/profile/settings",
        icon: <PersonIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
    },
    {
        title: strings.msg_issuer_address,
        path: "/profile/addresses/issuer",
        icon: <BusinessIcon />,
    },
    {
        title: strings.msg_receiver_addresses,
        path: "/profile/addresses/receiver",
        icon: <GroupIcon />,
    },
    {
        title: strings.msg_goods_and_services,
        path: "/profile/products",
        icon: <CodeIcon />,
    },
    {
        title: strings.msg_subscription,
        path: "/profile/subscription",
        icon: <AutorenewIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN],
    },
    {
        title: strings.msg_password,
        path: "/profile/change-password",
        icon: <LockIcon />,
    },

]


const drawerItemsDashboard = [
    {
        title: strings.msg_statistics,
        path: "/",
        icon: <TimelineIcon />,
    },
    {
        title: strings.msg_create_document,
        path: "/documents/add",
        icon: <NoteAddIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER],
    },
    {
        title: strings.msg_upload_documents,
        path: "/documents/upload",
        icon: <LibraryBooksIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER],
    },
    {
        title: strings.msg_documents,
        path: "/documents",
        icon: <DescriptionIcon />,
    },
    {
        title: strings.msg_drafts,
        path: "/drafts",
        icon: <BookmarksIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER],
    }, ,
    {
        title: strings.msg_daily_status,
        path: "/daily-status",
        icon: <BarChartIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER],
    },
    {
        title: strings.msg_schedule_documents,
        path: "/scheduled-uploads",
        icon: <ScheduleIcon />,
        roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.SUBMITTER],
    },
    {
        title: strings.msg_packages,
        path: "/packages",
        icon: <LayersIcon />,
    },
    {
        title: strings.msg_notifications,
        path: "/notifications",
        icon: <NotificationsIcon />,
    },
    {
        title: strings.msg_item_codes,
        path: "/codes",
        icon: <ViewWeekIcon />,
    },
    {
        title: strings.msg_tax_code,
        path: "/units",
        icon: <CodeIcon />,
    },
    {
        title: strings.msg_faq_section,
        path: "/faq",
        icon: <LiveHelpIcon />,
    },
]


const drawerWidth = 275;
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        display: 'flex',
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        position: 'sticky',
        top: 0,
        zIndex: 1200,
        padding: theme.spacing(1, 3, 1, 3),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuCategory: {

        margin: theme.spacing(2, 3, 2, 2),
        textAlign: 'initial',
        fontSize: '15px',
        color: '#FFA200',
        fontWeight: 'bold'

    },
    title: {
        // display: 'none',
        display: 'block',
        // [theme.breakpoints.up('sm')]: {
        //     display: 'block',
        // },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        // display: 'none',
        display: 'flex',
        alignItems: 'center',
        // [theme.breakpoints.up('md')]: {
        //     display: 'flex',
        // },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main
    },
    listItem: {
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    listItemFocused: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: "column",
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            marginLeft: +drawerWidth,
        },
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '0 100px',
        padding: theme.spacing(3, 0)
        //[theme.breakpoints.down('sm')]: {
        //flex: '1 0 15%',
        //}
    },
    notificationButton: {
        backgroundColor: '#EAF3FA',
        padding: theme.spacing(2, 2),
        minWidth: '100px',
        height: '50px',
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: 'rgba(2, 84, 146, 0.7)',
            cursor: 'default'
        },
    }
}));

function PrimarySearchAppBar(props) {
    const classes = useStyles();
    const userContext = useContext(UserContext)
    const history = useHistory()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [profileExpanded, setProfileExpanded] = React.useState(!props.noExpansion);
    const [dashboardExpanded, setDashboardExpanded] = React.useState(false);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const defaultListItem = {
        title: <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => history.goBack()}

        >
            <ArrowBackIosIcon />
        </IconButton>,
    }

    const selectedListItem = drawerItemsProfile.find(item => item.path == window.location.pathname) || defaultListItem

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };



    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        firebase.auth().signOut()
        handleMenuClose()
        history.push('/')
    }


    const handleLanguageChange = (e) => {
        localStorage.setItem('lang', e.target.getAttribute('value'))
        handleMenuClose()
        window.location.reload();
    }
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={anchorEl !== null && anchorEl.attributes['aria-controls'].value === menuId}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose} component={RouterLink} color="inherit" to="/" underline="none">{strings.msg_dashboard}</MenuItem>
            <MenuItem onClick={handleLogout}>{strings.msg_logout}</MenuItem>
        </Menu>
    );

    const languageMenuId = 'primary-language-menu';
    const renderLanguageMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={anchorEl !== null && anchorEl.attributes['aria-controls'].value === languageMenuId}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLanguageChange} value="en">{strings.msg_english}</MenuItem>
            <MenuItem onClick={handleLanguageChange} value="ar">{strings.msg_arabic}</MenuItem>
        </Menu>
    );


    const authenticated = firebase.auth().currentUser;
    const variant = isWidthUp('sm', props.width) ? "persistent" : "temporary"
    const drawer = authenticated ? <DrawerContext.Consumer>{({ open, toggleDrawer }) => (
        <Drawer
            className={classes.drawer}
            variant={variant}
            onClose={toggleDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            open={open}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}

        >
            <div className={classes.drawerContainer}>
                <List>
                    <div className={classes.logo}>
                        <Link
                            component={RouterLink}
                            underline="none"
                            to="/"
                            color="inherit"
                        >
                            <img src={language == 'ar' ? logo : logoEn} height={100} alt="Rabet Logo" />
                        </Link>
                    </div>
                    <Divider />
                    <List className={classes.menu}>
                        <ListItem button onClick={() => setProfileExpanded(!profileExpanded)}>
                            <ListItemIcon style={{ color: 'inherit' }} >
                                <AccountCircle />
                            </ListItemIcon>
                            <ListItemText className={classes.groupLabel} primary={strings.msg_profile} />
                            {profileExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={profileExpanded} timeout="auto" unmountOnExit>
                            {
                                drawerItemsProfile.map((item, idx) => {
                                    if (!item.roles || userContext.hasOneOfRoles(item.roles)) {
                                        if (item.type === 'category')
                                            return <div key={item.title} className={classes.menuCategory}>{item.title}</div>
                                        return <ListItem
                                            key={item.title}
                                            className={window.location.pathname == item.path ? classes.listItemFocused : classes.listItem}
                                            button
                                            color="inherit"
                                            onClick={() => {
                                                if (!isWidthUp('sm', props.width)) {
                                                    setTimeout(() => history.push(item.path), 300)
                                                    toggleDrawer();
                                                }
                                                else history.push(item.path)
                                            }}
                                        >
                                            <ListItemIcon style={{ color: 'inherit' }} >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItem>
                                    }
                                })
                            }
                        </Collapse>
                    </List>
                    <Divider />
                    <List className={classes.menu}>
                        <ListItem button onClick={() => setDashboardExpanded(!dashboardExpanded)}>
                            <ListItemIcon style={{ color: 'inherit' }} >
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText className={classes.groupLabel} primary={strings.msg_dashboard} />
                            {dashboardExpanded ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={dashboardExpanded} timeout="auto" unmountOnExit>

                            {
                                drawerItemsDashboard.map((item, idx) => {
                                    if (!item.roles || userContext.hasOneOfRoles(item.roles))
                                        return <ListItem
                                            key={item.title}
                                            className={window.location.pathname == item.path ? classes.listItemFocused : classes.listItem}
                                            button
                                            color="inherit"
                                            onClick={() => {
                                                if (!isWidthUp('sm', props.width)) {
                                                    setTimeout(() => history.push(item.path), 300)
                                                    toggleDrawer();
                                                }
                                                else history.push(item.path)
                                            }}
                                        >
                                            <ListItemIcon style={{ color: 'inherit' }} >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItem>
                                })
                            }
                        </Collapse>
                    </List>
                    <Divider />
                    <ListItem
                        button
                        component={RouterLink}
                        to="/"
                        color="inherit"
                        onClick={() => {
                            if (!isWidthUp('sm', props.width))
                                toggleDrawer();
                            handleLogout()
                        }}
                    >
                        <ListItemIcon style={{ color: 'inherit' }} >
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={strings.msg_logout} />
                    </ListItem>
                    <Divider />
                </List>
            </div>
        </Drawer>)}</DrawerContext.Consumer> : null;


    return (
        <>
            <Helmet>
                <title>{strings.msg_profile_title_meta}</title>
            </Helmet>
            <CssBaseline />
            <DrawerContext.Consumer>{({ open, toggleDrawer }) => (
                <div style={{ minHeight: "100vh", minHeight: "100dvh", display: 'flex', flexDirection: 'column' }}>
                    <Grid container component={Paper} alignItems="center" justifyContent="space-between" className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
                        <Grid item >
                            <Grid container>
                                <Grid item>
                                    {authenticated ? (
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={toggleDrawer}
                                        //className={clsx(classes.menuButton, { [classes.appBarShift]: open, })}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>
                                <Grid item>
                                    {
                                        selectedListItem.path ?
                                            <Typography className="font-family-cairo primary-color" color="secondary" display="inline" variant="overline" style={{ fontSize: '20px' }}>{selectedListItem.title}</Typography>
                                            : selectedListItem.title
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    {authenticated ? (
                                        <Button component="div" disableRipple disableFocusRipple variant="contained" className={classes.notificationButton} >
                                            <Link
                                                component={RouterLink}
                                                to="/"
                                                color="inherit"
                                                className={classes.listItem}
                                            >
                                                <AccountCircle />
                                                <Typography style={{ padding: '0px 10px 0px 5px' }} variant="overline">
                                                    {authenticated.email.split('@')[0]}
                                                </Typography>

                                            </Link>
                                            <IconButton
                                                edge="end"
                                                aria-label="language menu"
                                                aria-controls={languageMenuId}
                                                aria-haspopup="true"
                                                onClick={handleMenuOpen}
                                                color="inherit"
                                            >
                                                <LanguageIcon />
                                            </IconButton>
                                        </Button>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>



                    {drawer}

                    {renderMenu}
                    {renderLanguageMenu}

                    <main className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}>
                        <Toolbar />
                        {props.children}
                        <Toolbar />
                    </main>
                    <DashboardFooter className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })} />
                </div>)}
            </DrawerContext.Consumer>
        </>
    );
}

export default withWidth()(PrimarySearchAppBar)