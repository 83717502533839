import React, { Component, createContext } from "react";
import { notificationAPI } from "../api";
// import { firebase } from "../components/firebase";
export const NotificationContext = createContext({ notifications: [], notificationCount: 0, timer: null, });
class NotificationProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            notificationCount: 0,
            timer: null,
        };
    }
    getNotifications = () => {
        notificationAPI
            .get(``)
            .then((response) => {
                let notifications = response.data
                let notificationCount = 0;
                if (notifications.metadata) {
                    notificationCount = notifications.metadata.totalCount;
                    if (notifications.metadata.totalPages > 1) notificationCount += "+";
                }
                this.setState({ notifications: notifications, notificationCount, })
            })
            .catch((err) => console.log(err));
    }
    componentDidMount = () => {
        // firebase.auth().onAuthStateChanged(userAuth => {
        //     if (userAuth) {
        //         this.getNotifications();
        //         // const timer = setInterval(() => this.getNotifications(), 5 * 60 * 1000);
        //         // this.setState({ timer, })
        //     }
        //     // else this.clearTimer()
        // });
    };
    clearTimer = () => {
        if (this.state.timer)
            clearInterval(this.state.timer)
    }
    componentWillUnmount = () => {
        this.clearTimer()
    }
    render() {
        return (
            <NotificationContext.Provider value={this.state}>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}
export default NotificationProvider;